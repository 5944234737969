import React from "react"
import { observer, PropTypes } from "mobx-react"
import { Row, Col } from "reactstrap"
import Link from "../Link/index"
import Icon from "../Icon"
import ImageComparison from "../ImageComparison/index"
import { projectPage } from "../../routes/index"
import { TAB_GALLERY } from "../Property"

const GalleryTab = ({ property }) => {
  const activeProjectsWithImages = property.activeProjectsWithImages

  return (
    <Row className="row-space-items">
      {activeProjectsWithImages === 0 && (
        <Col className="mt-4 text-center">
          <Icon icon="add_photo_alternate" size="xl" />
          <h5>Looks like you don't have any images of this Reno yet...</h5>
          <p>
            Add photos to your budget categories and they will also appear here,
            all together
          </p>
        </Col>
      )}
      {property.activeProjects.length > 0 &&
        property.activeProjects.map((project, idx) => {
          return (
            <Col sm="6" lg="4" key={`${project.id}-${idx}`}>
              <h5>
                <span className="mr-2">{project.name}</span>
                <Link
                  to={projectPage.url({ id: project.id, tab: TAB_GALLERY })}
                >
                  <strong className="tiny allcaps d-inline-block">
                    Add / Edit photos
                  </strong>
                </Link>
              </h5>

              <ProjectImagesGallery project={project} />
            </Col>
          )
        })}
    </Row>
  )
}

function ProjectImagesGallery({ project }) {
  return project.hasImages ? (
    project.images.map(([before, after]) => {
      const beforeImage = project.getImageSrc(before)
      const afterImage = project.getImageSrc(after)

      return beforeImage || afterImage ? (
        <span key={before || after}>
          {beforeImage && afterImage && (
            <ImageComparison leftImage={beforeImage} rightImage={afterImage} />
          )}
          {beforeImage && !afterImage && (
            <img src={beforeImage} alt="beforeImage" />
          )}
          {!beforeImage && afterImage && (
            <img src={afterImage} alt="afterImage" />
          )}
        </span>
      ) : null
    })
  ) : (
    <p className="italic small mb-0">
      You haven't added any images to this budget category yet!
    </p>
  )
}

GalleryTab.propTypes = {
  property: PropTypes.observableObject.isRequired
}

export default observer(GalleryTab)
