import { useLocalStore } from "mobx-react"
import React, { FunctionComponent } from "react"
import { RootStore, RootStoreType } from "../stores/RootStore"

const storeContext = React.createContext<RootStoreType | null>(null)

export const RootStoreProvider: FunctionComponent = ({ children }) => {
  const rootStore = useLocalStore(() => RootStore.create())

  return (
    <storeContext.Provider value={rootStore}>{children}</storeContext.Provider>
  )
}

export const useRootStore = () => {
  const store = React.useContext(storeContext)
  if (!store) {
    throw new Error("useStore must be used within a StoreProvider.")
  }
  return store
}
