import React, { Component } from "react"
import PropTypes from "prop-types"
import { PropTypes as MobxTypes } from "mobx-react"
import { ReactComponent as Plus } from "../../images/white-plus.inline.svg"
import { Input } from "reactstrap"
import { format } from "../../utils/price"
import Button from "../Button"
import PriceField from "../../models/PriceField"
import { generate } from "../../utils/uuid"

const INITIAL_STATE = {
  adding: false,
  name: "",
  value: 0
}

class AddCustomField extends Component {
  state = INITIAL_STATE

  static propTypes = {
    profitAndLoss: MobxTypes.observableObject.isRequired,
    type: PropTypes.string.isRequired
  }

  setValue = ({ target: { value } }) => {
    this.setState({ value: parseFloat(value.replace(/[^0-9.-]/g, "")) })
  }

  setName = ({ target: { value } }) => {
    this.setState({ name: value })
  }

  saveField = () => {
    const { profitAndLoss, type } = this.props
    const { name, value } = this.state
    const field = PriceField.create({
      id: generate(),
      custom: true,
      name,
      value,
      type
    })
    profitAndLoss.addField(field)
    this.setState(INITIAL_STATE)
  }

  render() {
    const { name, value, adding } = this.state
    let rows = []
    if (adding) {
      rows.push(
        <tr
          key="adding-custom-field"
          className="budget-item-row budget-item-row--default"
        >
          <td>
            <Input type="text" value={name} onChange={this.setName} />
          </td>
          <td className="text-right">
            <Input type="text" value={format(value)} onChange={this.setValue} />
          </td>
        </tr>
      )
    }
    return [
      rows.concat(
        <tr key="custom-field">
          <td>
            {!adding && (
              <Button
                onClick={e => {
                  e.preventDefault()
                  this.setState({ adding: true })
                }}
                color="primary"
              >
                <Plus className="btn-swatch__icon" /> New Item
              </Button>
            )}
            {adding && (
              <div>
                <Button
                  onClick={e => {
                    e.preventDefault()
                    this.saveField()
                  }}
                  color="primary"
                >
                  Save
                </Button>
                <Button
                  onClick={e => {
                    e.preventDefault()
                    this.setState({ adding: false })
                  }}
                  color="secondary"
                >
                  Cancel
                </Button>
              </div>
            )}
          </td>
        </tr>
      )
    ]
  }
}

export default AddCustomField
