import React, { PureComponent } from "react"
import { Button } from "reactstrap"
import PropTypes from "prop-types"
import { PropTypes as MobxTypes, inject } from "mobx-react"
import { createFromProject } from "../../models/Template"
import Icon from "../Icon"

class SaveAsTemplateButton extends PureComponent {
  static defaultProps = {
    value: "Use as template",
    conflictMessage: "Sorry a template with this name already exists",
    successMessage: "Template created"
  }

  static propTypes = {
    project: MobxTypes.observableObject.isRequired,
    onExisting: PropTypes.func,
    then: PropTypes.func,
    value: PropTypes.string,
    conflictMessage: PropTypes.string,
    successMessage: PropTypes.string,
    size: PropTypes.string
  }

  onClick = async e => {
    const {
      project,
      then,
      onExisting,
      renoTracker,
      conflictMessage,
      successMessage
    } = this.props
    e.preventDefault()
    try {
      const template = await createFromProject(project)
      renoTracker.notify(successMessage, { level: "success" })
      if (then) then(template)
    } catch (e) {
      if (e.error === "conflict") {
        if (onExisting) onExisting(e)
        else renoTracker.notify(conflictMessage)
      } else throw e
    }
  }

  render() {
    const { value, size, renoTracker } = this.props
    return renoTracker.isAuthenticated && renoTracker.user.isAdmin ? (
      <Button size={size} onClick={this.onClick}>
        <Icon icon="filter_none" /> {value}
      </Button>
    ) : null
  }
}

export default inject("renoTracker")(SaveAsTemplateButton)
