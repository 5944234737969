import { types } from "mobx-state-tree"
import { format } from "../utils/price"

const OverviewStats = types.model("OverviewStats").views(self => ({
  get budgetFormatted() {
    return format(self.totalBudget)
  },
  get spentFormatted() {
    return format(self.spent)
  },
  get availableFormatted() {
    return format(self.available)
  },
  get percent() {
    return self.spent && self.totalBudget ? self.spent / self.totalBudget : 0
  },
  get percentAvailable() {
    if (!self.spent || !self.totalBudget) {
      return 100
    }
    return ((1 - self.percent) * 100).toFixed(2)
  },
  get percentUsed() {
    if (!self.spent || !self.totalBudget) {
      return 0
    }
    return (self.percent * 100).toFixed(2)
  },
  get percentAvailableFormatted() {
    return `${Math.round(self.percentAvailable)}%`
  },
  get percentUsedFormatted() {
    return `${Math.round(self.percentUsed)}%`
  }
}))

export default OverviewStats
