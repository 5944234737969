import React from "react"
import ListTemplates from "../ListTemplates/index"
import { observer } from "mobx-react"

const TemplatesPage = ({ renoTracker }) => {
  return (
    <span>
      <div className="text-center">
        <h1 className="swatch-title swatch-title--main">Templates</h1>
      </div>
      <ListTemplates templates={renoTracker.activeTemplates} />
    </span>
  )
}

export default observer(TemplatesPage)
