import React from "react"
import { Card, CardBody, CardTitle, Row, Col } from "reactstrap"
import { observer } from "mobx-react"
import { templatePage } from "../../routes/index"
import Link from "../Link/index"

const ListTemplates = ({ templates }) => {
  return (
    <Row>
      {templates.map(template => (
        <Col key={template.id} xs="12" sm="6" lg="4">
          <Card className="mb-2 card-raised">
            <Link
              to={templatePage.url({ id: template.id })}
              className="as-link"
            >
              <CardBody>
                <CardTitle className="mb-0">{template.name}</CardTitle>
              </CardBody>
            </Link>
          </Card>
        </Col>
      ))}
    </Row>
  )
}

export default observer(ListTemplates)
