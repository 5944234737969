import React, { Component } from "react"
import { PropTypes } from "prop-types"
import { observer, PropTypes as MobxTypes } from "mobx-react"
import { Form, FormGroup, Label, Nav, NavItem, Input } from "reactstrap"
import InputSet from "../InputSet"
import Icon from "../Icon"
import Button from "../Button"
import { propertyPage } from "../../routes/index"
import ImageField from "../ImageField"
import { route } from "../../utils/router"
import AddressFinder from "../AddressFinder"
import { STATUS_ACTIVE } from "../../constants"

class PropertyForm extends Component {
  static propTypes = {
    property: MobxTypes.observableObject.isRequired,
    isEditingExisting: PropTypes.bool,
    onSaved: PropTypes.func,
    onDelete: PropTypes.func,
    cancelURL: PropTypes.string
  }

  state = { hasValidationErrorAddress: false }

  onSave = async () => {
    if (this.validateForm()) {
      const { isEditingExisting, property, onSaved } = this.props
      if (!isEditingExisting) {
        property.setStatus(STATUS_ACTIVE)
      }
      await property.save()
      if (onSaved) {
        if (typeof onSaved === "string") route(property.viewer, onSaved)
        else onSaved(property)
      } else {
        propertyPage.route(property.viewer, { id: property.id })
      }
    }
  }

  validateForm = () => {
    // check address
    // TIM TODO currently checking via name... bit hacky, but unsure why property still has an address value even if it's empty
    // ALSO note this only works first time (aka it won't work if we do set an address, then remove it and try to save... again cos it's not actually working from real address field)
    if (!this.props.property.name) {
      this.setState({ hasValidationErrorAddress: true })
      return false
    } else {
      this.setState({ hasValidationErrorAddress: false })
    }

    return true
  }

  onAddressSelect = address => {
    const { property } = this.props
    property.setAddress(address)
    this.validateForm()
  }

  setName = ({ target: { value } }) => {
    this.props.property.setName(value)
  }

  setSharedWith = ({ target: { value } }) => {
    this.props.property.setSharedWith(value)
  }

  render() {
    const {
      property,
      cancelURL,
      isEditingExisting = true,
      onDelete
    } = this.props

    const addressFinderKey = process.env.REACT_APP_ADDRESS_FINDER_KEY

    if (!cancelURL)
      return (
        <Form>
          {onDelete && (
            <Nav className="property-detail-nav property-detail-nav--right mb-2">
              <NavItem>
                <Button
                  icon
                  onClick={() => onDelete(property)}
                  title="Delete this project and all of it's details"
                  className="text-danger"
                >
                  <Icon icon="delete" /> Delete Reno
                </Button>
              </NavItem>
            </Nav>
          )}
          <FormGroup>
            <Label for="name">Property Address</Label>
            <AddressFinder
              apiKey={addressFinderKey}
              onSelect={this.onAddressSelect}
              render={({ setRef }) => (
                <InputSet
                  type="text"
                  innerRef={setRef}
                  defaultValue={property.name}
                  invalid={this.state.hasValidationErrorAddress}
                  feedback={
                    this.state.hasValidationErrorAddress
                      ? "Hang on! You need to enter the property address..."
                      : null
                  }
                />
              )}
            />
          </FormGroup>
          {isEditingExisting && (
            <FormGroup>
              <Label for="name">Invite Users</Label>
              <Input
                type="text"
                value={property.name}
                onChange={this.setSharedWith}
              />
            </FormGroup>
          )}
          <FormGroup>
            <Label for="name">Image</Label>
            <ImageField
              resize={{ width: 640, height: 480 }}
              fileUrl={property.imageSrc}
              onDropped={file => property.addImage(file)}
            />
          </FormGroup>
          <Nav className="justify-content-center align-items-center nav-space-items">
            {/* <NavItem>
              <NavLink
                href={
                  isEditingExisting
                    ? propertyPage.url({ id: property.id })
                    : home.url()
                }
              >
                <Button swatch color="secondary">
                  Back
                </Button>
              </NavLink>
            </NavItem> */}
            <NavItem>
              <Button
                swatch
                color={isEditingExisting ? "secondary" : "primary"}
                onClick={this.onSave}
              >
                {isEditingExisting ? "Back" : "Next"}
              </Button>
            </NavItem>
          </Nav>
        </Form>
      )
  }
}

export default observer(PropertyForm)
