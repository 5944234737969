import request from "./request"

export function sendLoginLink(email) {
  const url = `${process.env.REACT_APP_API_URI}/auth/email-link`
  return request(url, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ email })
  })
}

export function validateLoginLink(key) {
  const url = `${process.env.REACT_APP_API_URI}/auth/validate-link?key=${key}`
  return request(url)
}
