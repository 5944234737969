import React from "react"
import Button from "../Button"
import { addProperty } from "../../routes/index"
import { observer, inject } from "mobx-react"

import { ReactComponent as Plus } from "../../images/white-plus.inline.svg"

const AddPropertyButton = ({
  viewer,
  className,
  color = "primary",
  size = "md",
  title = "Add Reno",
  swatch = "lg"
}) => (
  <Button
    size={size}
    swatch={swatch}
    color={color}
    onClick={() => addProperty.route(viewer)}
    className={className}
  >
    <Plus className="btn-swatch__icon btn-swatch__icon--larger" />
    <br />
    {title}
  </Button>
)

export default inject("viewer")(observer(AddPropertyButton))
