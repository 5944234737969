import React from "react"
import { observer, PropTypes as MobxTypes } from "mobx-react"
import Acquisition from "./Acquisition"
import SaleProceeds from "./SaleProceeds"
import Financial from "./Financial"

function TradeTracker({ profitAndLoss }) {
  const onChange = ({ target: { value, name } }) => {
    profitAndLoss.setPrice(name, value)
  }

  const onChangeName = ({ target: { value, name } }) => {
    profitAndLoss.setName(name, value)
  }

  const onRemove = field => {
    profitAndLoss.removeField(field.id)
  }

  return (
    <div>
      <h3 className="mt-1">Acquisition Costs</h3>
      <Acquisition
        profitAndLoss={profitAndLoss}
        onChange={onChange}
        onChangeName={onChangeName}
        onRemove={onRemove}
      />
      <h3 className="mt-5">Calculation of Sale Proceeds</h3>
      <SaleProceeds
        profitAndLoss={profitAndLoss}
        onChange={onChange}
        onChangeName={onChangeName}
        onRemove={onRemove}
      />
      <h3 className="mt-5">Financing and Other Costs (assuming no GST)</h3>
      <Financial
        profitAndLoss={profitAndLoss}
        onChange={onChange}
        onChangeName={onChangeName}
        onRemove={onRemove}
      />
    </div>
  )
}

TradeTracker.propTypes = {
  profitAndLoss: MobxTypes.objectOrObservableObject.isRequired
}

export default observer(TradeTracker)
