import React from "react"
import { Row, Col, Card } from "reactstrap"
import { observer } from "mobx-react"
import Link from "../Link"
import { propertyPage } from "../../routes/index"
import Icon from "../Icon"
import AddPropertyButton from "../AddPropertyButton"
import Progress from "../Progress"

import "./property.scss"
import { TAB_GALLERY } from "../Property"
import { STATUS_COMPLETED } from "../../constants"

const ListProperties = ({ properties, renoTracker, showAdd }) => {
  return (
    <span>
      {!renoTracker.hasProperties && (
        <div className="text-center">
          <AddPropertyButton
            className="my-5 property-list-add-property"
            renoTracker={renoTracker}
            title={
              <span>
                <small>Start</small>
                <br />
                First Reno
              </span>
            }
          />
        </div>
      )}

      {renoTracker.hasProperties && (
        <Row className="row-space-items">
          {showAdd && (
            <Col
              xs="6"
              md="4"
              lg="3"
              className="align-items-center d-flex justify-content-center"
            >
              <AddPropertyButton
                className="property-list-add-property"
                renoTracker={renoTracker}
                title={
                  <span>
                    <small>Start</small>
                    <br />
                    New Reno
                  </span>
                }
              />
            </Col>
          )}
          {properties.map(property => {
            const status = property.status
            const progressBarIcon =
              status === STATUS_COMPLETED ? (
                <Icon icon="check" size="md" />
              ) : (
                false
              )

            const progressPercent = (
              (property.percent || property.dbPercent) * 100
            ).toFixed(2)
            const percentFormated = progressPercent
              ? `${Math.round(progressPercent)}%`
              : ""

            return (
              <Col key={property.id} xs="6" md="4" lg="3">
                <Card className="card-raised h-100">
                  <Link to={propertyPage.url({ id: property.id })}>
                    {!property.imageSrc && (
                      <div className="property-preview-image property-preview-image-default" />
                    )}
                    {property.imageSrc && (
                      <div
                        className="property-preview-image"
                        style={{ backgroundImage: `url(${property.imageSrc})` }}
                      />
                    )}
                  </Link>
                  <div className="property-preview-details">
                    <Progress
                      className="property-progress"
                      percentage={
                        progressPercent > 100
                          ? progressPercent - 100
                          : progressPercent
                      }
                      text={status === STATUS_COMPLETED ? "" : percentFormated}
                      icon={progressBarIcon}
                      status={progressPercent > 100 ? "danger" : false}
                      withBG
                    />
                    <Link
                      className="property-title btn property-preview-button property-preview-button-name"
                      to={propertyPage.url({ id: property.id })}
                    >
                      <h5 className="mb-0">{property.name}</h5>
                    </Link>
                    <Link
                      className="btn property-preview-button property-preview-button-gallery d-none d-sm-block"
                      to={propertyPage.url({
                        id: property.id,
                        tab: TAB_GALLERY
                      })}
                    >
                      <Icon icon="photo_library" size="md" />
                      <br />
                      <span>Gallery</span>
                    </Link>
                  </div>
                </Card>
              </Col>
            )
          })}
        </Row>
      )}
    </span>
  )
}

export default observer(ListProperties)
