import { types, flow, getRoot, resolveIdentifier } from "mobx-state-tree"
import { isEqual } from "lodash/lang"
import Property from "./Property"
import Project from "./Project"
import db from "../utils/db"
import { applyFilters } from "../utils/hooks"
import Template from "./Template"
import { generate } from "../utils/uuid"
import { validateLoginLink } from "../utils/auth"
import { STATUS_COMPLETED } from "../constants"

export const ADD_PROPERTY = "add-property"
export const ADD_PROJECT = "add-project"
export const PROPERTY_PAGE = "property"
export const GALLERY_PAGE = "property_gallery"
export const PROJECT_PAGE = "project"
export const HOME = "home"
export const REGISTER = "register"
export const LOGIN = "login"
export const LOGOUT = "logout"
export const TEMPLATES_PAGE = "templates_page"
export const TEMPLATE_PAGE = "template_page"
export const SELECT_AVATAR_PAGE = "select_avatar_page"
export const VERIFY_PAGE = "verify_page"
export const PLAN_PAGE = "plan_page"

class NotFoundError extends Error {}

export const ViewStore = types
  .model({
    page: LOGIN,
    params: types.optional(types.frozen(), {}),
    error: types.optional(types.frozen(), null),
    activeTemplate: types.maybeNull(types.reference(Template)),
    activeProperty: types.maybeNull(types.reference(Property)),
    routeReady: false,
    routeFound: true,
    paramsChanged: false,
    pageChanged: false,
  })
  .views((self) => ({
    get app() {
      return getRoot(self)
    },
    get user() {
      return self.app.user
    },
    get currentUrl() {
      if (self.routeReady) {
        const route = applyFilters("routes", []).find((r) => r.isActive(self))
        if (route) {
          return route.url(route.argsFromState(self), self)
        }
      }
      return null
    },
    get propertyId() {
      return self.activeProperty ? self.activeProperty.id : undefined
    },
    get activeProject() {
      return self.activeProperty ? self.activeProperty.activeProject : undefined
    },
  }))
  .actions((self) => {
    const setPage = (page) => {
      self.pageChanged = self.page !== page
      self.page = page
    }

    const doRoute = (
      routeFn,
      {
        withAuth = true,
        withAdmin = false,
        async = true,
        page = null,
        params = {},
        doLoader = null,
      } = {}
    ) =>
      flow(function* () {
        try {
          if (page !== self.page || doLoader) {
            self.routeReady = false
          }
          self.paramsChanged = !isEqual(params, self.params)
          self.error = null

          if (withAuth) {
            try {
              const user = yield self.app.loadForUser()
              if (!user) {
                console.log("calling open login page")
                return self.openLoginPage()
              }
            } catch (e) {
              console.log("withAuth error")
              console.error(e)
              return self.openLoginPage()
            }
          }

          if (withAdmin && !self.user.isAdmin) {
            self.app.notify("You must be an admin to access this page.")
            return self.openLoginPage()
          }

          if (params) {
            self.params = params
          }

          if (page) {
            setPage(page)
          }

          if (routeFn) {
            // Provides a warning to developers that don't pass async:false when calling
            // non async routes.
            if (
              process.env.NODE_ENV === "development" &&
              routeFn.constructor.name !== "GeneratorFunction" &&
              async
            ) {
              console.error(
                "It looks like you are calling a non async route, with async true. You should pass async:false if this route isn't async ( no yield statement )"
              )
            }
            // Note routeFn.constructor.name is munged during build
            // so we have to rely on the async prop here instead of the above GeneratorFunction test
            if (async) {
              self.routeVars = yield flow(routeFn)()
            } else {
              self.routeVars = routeFn()
            }
          }
          self.paramsChanged = false
          self.routeReady = true
        } catch (e) {
          self.paramsChanged = false
          self.routeReady = true
          if (e instanceof NotFoundError || e.status === 404) {
            self.setRouteFound(false)
          } else {
            console.log("Uncaught error in doRoute")
            self.error = e
            throw e
          }
        }
      })()

    const getProperty = (id) => {
      const property = self.app.getProperty(id)
      if (!property) {
        throw new NotFoundError("Property not found")
      }
      return property
    }

    return {
      openHomePage() {
        return doRoute(
          () => {
            // if (!self.user.hasPlan) {
            //   return self.openPlanPage()
            // }
            if (!self.user.hasAvatar) {
              return self.openAvatarPage()
            }
            self.app.setPropertyPercentValues()
          },
          { page: HOME, params: {}, async: false }
        )
      },

      openAvatarPage() {
        return doRoute(null, { page: SELECT_AVATAR_PAGE })
      },

      openRegisterPage() {
        return doRoute(null, { withAuth: false, page: REGISTER })
      },

      openPlanPage() {
        return doRoute(null, { page: PLAN_PAGE })
      },

      openLoginPage(params) {
        return doRoute(null, { withAuth: false, page: LOGIN, params })
      },

      openLogoutPage() {
        return doRoute(function* () {
          yield self.app.logout()
          self.openLoginPage()
        })
      },

      openAddPropertyPage() {
        return doRoute(
          () => {
            const property =
              self.app.draftProperty || Property.create({ id: generate() })
            self.activeProperty = self.app.addProperty(property)
          },
          { page: ADD_PROPERTY, params: {}, async: false }
        )
      },

      openPropertyPage(params) {
        const { id, search } = params
        return doRoute(
          () => {
            self.activeProperty = getProperty(id)
            // Reset fuse now in case things for searched for changed since last load
            self.activeProperty.resetFuse()
            if (search) {
              self.activeProperty.search(search)
            }

            const pAndLTemplate = self.app.getOrLoadPALTemplate()

            self.activeProperty.profitAndLoss.setValue(
              "renoCosts",
              self.activeProperty.status === STATUS_COMPLETED
                ? self.activeProperty.spent
                : self.activeProperty.totalBudget
            )

            if (pAndLTemplate) {
              self.activeProperty.profitAndLoss.setFromTemplate(pAndLTemplate)
            }
          },
          { page: PROPERTY_PAGE, params, async: false }
        )
      },

      openGalleryPage({ id, ...params }) {
        return doRoute(
          function* () {
            self.activeProperty = getProperty(id)
            yield self.activeProperty.loadProjects()
          },
          { page: GALLERY_PAGE, params }
        )
      },

      openAddProjectPage(params) {
        const { propertyId } = params
        return doRoute(
          function* () {
            self.activeProperty = getProperty(propertyId)
            yield Promise.all([
              self.activeProperty.loadProjects(),
              self.app.loadTemplates(),
            ])
          },
          { page: ADD_PROJECT, params }
        )
      },

      openProjectPage(params) {
        return doRoute(
          function* () {
            const { id } = params
            let project = resolveIdentifier(Project, getRoot(self), id)
            if (!project) {
              // We don't already have this project in state
              const res = yield db.get(id)
              self.activeProperty = getProperty(res.propertyId)
              const property = self.activeProperty
              if (property) {
                yield property.loadProjects()
                project = property.projects.get(id)
                if (!project) {
                  throw new NotFoundError("Project not found")
                }
                // TODO: hack around `saved` reaction until we get rid of it altogether
                project.setSaved(true)
              }
            } else {
              self.activeProperty = getProperty(project.property.id)
              project.setSaved(true)
            }
            project.property.setActiveProject(id)
          },
          { page: PROJECT_PAGE, params }
        )
      },

      openTemplatePage(params) {
        return doRoute(
          function* () {
            const { id } = params
            self.activeTemplate = yield self.app.loadTemplate(id)
          },
          { page: TEMPLATE_PAGE, params, withAdmin: true }
        )
      },

      openTemplatesPage() {
        return doRoute(
          function* () {
            yield self.app.loadTemplates()
          },
          { page: TEMPLATES_PAGE, withAdmin: true }
        )
      },

      openVerifyPage(params) {
        return doRoute(
          function* () {
            try {
              const { email, password } = yield validateLoginLink(params.key)
              yield self.app.authenticate(email, password)
              return self.openHomePage()
            } catch (e) {
              const message = e.json ? e.json.message : e.message
              return self.openLoginPage({
                message: `Sorry, that didn't work :( Maybe try one more time ? (${message})`,
              })
            }
          },
          { page: VERIFY_PAGE, withAuth: false, params }
        )
      },

      setRouteFound(value) {
        self.routeFound = value
      },

      deleteProperty({ propertyId }) {
        return doRoute(function* () {
          yield self.app.deleteProperty(propertyId)
          return self.openHomePage()
        })
      },

      setParams(params) {
        self.params = {
          ...self.params,
          ...params,
        }
      },
    }
  })

export default ViewStore
