import React from "react"
import PropTypes from "prop-types"
import { CardElement, injectStripe } from "react-stripe-elements"
import Button from "../Button"

function StripePayment({ onSubmit, onCancel, stripe, tokenData }) {
  const submit = () => {
    stripe.createToken(tokenData).then(result => {
      if (result.token) {
        onSubmit(result.token)
      }
      if (result.error) {
        throw result.error
      }
    })
  }

  return (
    <div className="checkout">
      <CardElement />
      <div style={{ padding: ".5rem 0" }}>
        <Button color="primary" onClick={submit}>
          Activate
        </Button>
        {onCancel && <Button onClick={onCancel}>Cancel</Button>}
      </div>
    </div>
  )
}

StripePayment.propTypes = {
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  tokenData: PropTypes.object
}

export default injectStripe(StripePayment)
