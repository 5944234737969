import React from "react"
import PropTypes from "prop-types"
import { PropTypes as MobxTypes, observer } from "mobx-react"
import { Nav, NavItem } from "reactstrap"
import AddPropertyButton from "../AddPropertyButton/index"
import PropertyLink from "../PropertyLink/index"

const PropertiesNav = ({ renoTracker, active }) => (
  <Nav vertical>
    {renoTracker.activeProperties.map(property => (
      <NavItem key={property.id}>
        <PropertyLink property={property} />
      </NavItem>
    ))}
    <NavItem className="my-3">
      <AddPropertyButton
        swatch={false}
        renoTracker={renoTracker}
        title="Add property"
        size="sm"
        color="secondary"
      />
    </NavItem>
  </Nav>
)

PropertiesNav.propTypes = {
  renoTracker: MobxTypes.observableObject,
  active: PropTypes.string
}

export default observer(PropertiesNav)
