import React, { Component, Fragment } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { PropTypes as MobxTypes, observer, inject } from "mobx-react"
import { Row, Col, Label, Input } from "reactstrap"
import Button from "../Button"
import Icon from "../Icon"
import ListProjectsGrid from "../ListProjectsGrid"
import ListProjectsDetail from "../ListProjectsDetail"
import ListSuggestedItems from "../ListSuggestedItems"
import AddProjectButton from "../AddProjectButton"
import { projectPage } from "../../routes/index"
import Project from "../../models/Project"
import { generate } from "../../utils/uuid"

const DISPLAY_GRID = "grid"
const DISPLAY_DETAIL = "detail"

class BudgetTab extends Component {
  static propTypes = {
    defaultMode: PropTypes.oneOf([DISPLAY_DETAIL, DISPLAY_GRID]),
    property: MobxTypes.observableObject.isRequired
  }

  static defaultProps = {
    defaultMode: DISPLAY_GRID
  }

  state = {
    detailModeShowingSuggestions: false
  }

  toggleDetailModeSuggestions = () => {
    this.setState({
      detailModeShowingSuggestions: !this.state.detailModeShowingSuggestions
    })
  }

  displayDetail = () => {
    const viewer = this.props.viewer
    if (viewer.params.mode !== DISPLAY_DETAIL) {
      viewer.setParams({ mode: DISPLAY_DETAIL })
    }
  }

  displayGrid = () => {
    const { viewer } = this.props
    if (viewer.params.mode !== DISPLAY_GRID) {
      viewer.setParams({ mode: DISPLAY_GRID })
      this.clearSearch()
    }
  }

  toggleSuggestions = () => {
    const params = this.props.viewer.params
    this.props.viewer.setParams({
      suggestions: params.suggestions ? false : true
    })
  }

  clearSearch = () => {
    const { viewer, property } = this.props
    viewer.setParams({ search: "" })
    property.clearSearch()
  }

  search = e => {
    const v = e.target.value
    const { viewer, property } = this.props
    viewer.setParams({ search: v })
    if (!v) {
      this.clearSearch()
    } else {
      this.displayDetail()
      property.search(v)
    }
  }

  filterSearch = item => {
    const { property, viewer } = this.props
    if (viewer.params.search)
      return property.searchMatchedItemIds.includes(item.id)
    return true
  }

  // shouldn't be needed as this should be part of searchable ( searchMatchedItemIds )
  // filterExisting = item => {
  //   const { property } = this.props
  //   return !property.activeItemNames.includes(item.name)
  // }

  addSuggestion = async template => {
    const project = this.props.property.addProject(
      Project.create({ id: generate() })
    )
    project.setTemplate(template.id)
    project.setName(template.name)
    project.validate()
    if (project.isValid) {
      await project.save()
      projectPage.route(project.app.view, { id: project.id })
    } else {
      project.validationFailures.map(({ message }) =>
        project.app.notify(message, { level: "warning" })
      )
    }
  }

  renderSuggestions = () => {
    const property = this.props.property
    const suggestions = property.unusedTemplates
      .map(template => {
        const items = template.allItems.filter(this.filterSearch)
        // .filter(this.filterExisting)
        return items.length > 0 ? (
          <ListSuggestedItems
            renderTitle={() => (
              <h5>
                <span className="mr-2">{template.name}</span>
                <Button
                  size="sm"
                  color="as-link"
                  className="allcaps"
                  onClick={() => this.addSuggestion(template)}
                >
                  View &amp; Add
                </Button>
              </h5>
            )}
            key={template.id}
            items={items}
          />
        ) : null
      })
      .filter(Boolean)

    if (suggestions.length > 0) {
      return (
        <Fragment>
          <h3 className="mt-5 mb-3">Suggested Categories &amp; Items</h3>
          {suggestions}
        </Fragment>
      )
    }
    return null
  }

  render() {
    const { property, viewer } = this.props
    const { detailModeShowingSuggestions } = this.state
    const { mode, search = "" } = viewer.params
    const isGridMode = !mode || mode === DISPLAY_GRID
    const isDetailMode = mode === DISPLAY_DETAIL
    const hasProjects =
      property.activeProjects && property.activeProjects.length > 0
    const searching = !!search

    return (
      <Fragment>
        {hasProjects && (
          <Row className="mb-4 align-items-center">
            <Col xs={{ size: "12", order: "2" }} sm className="d-flex">
              <Input
                value={search}
                placeholder="Find a budget item"
                onChange={this.search}
              />
              {searching && (
                <Button onClick={this.clearSearch} className="nowrap">
                  <Icon icon="clear" />
                  <span className="d-none d-md-inline"> Clear Search</span>
                </Button>
              )}
            </Col>
            <Col
              xs={{ size: "12", order: "1" }}
              sm={{ size: "auto", order: "3" }}
              className="mb-2 mb-sm-0"
            >
              <Label className="mr-2">View</Label>
              <Button
                className={classNames({ active: isGridMode })}
                onClick={this.displayGrid}
                title="Grid View"
              >
                <Icon icon="view_module" size="md" />
              </Button>
              <Button
                className={classNames({ active: isDetailMode })}
                onClick={this.displayDetail}
                title="Detail View"
              >
                <Icon icon="list" size="md" />
              </Button>
            </Col>
          </Row>
        )}

        {isGridMode && <ListProjectsGrid projects={property.activeProjects} />}

        {isDetailMode && (
          <Fragment>
            <ListProjectsDetail
              projects={property.activeProjects}
              filter={this.filterSearch}
              showAddNew={!searching}
              showSuggested={searching}
              searching={searching}
            />

            {(searching || detailModeShowingSuggestions) &&
              this.renderSuggestions()}

            {!searching && (
              <span>
                <AddProjectButton className="mr-2" />
                <Button
                  color="secondary"
                  onClick={this.toggleDetailModeSuggestions}
                  size="sm"
                  className="italic"
                >
                  {detailModeShowingSuggestions ? "Hide" : "Show"} suggestions
                </Button>
              </span>
            )}
          </Fragment>
        )}
      </Fragment>
    )
  }
}

export default inject("viewer")(observer(BudgetTab))
