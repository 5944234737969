import React from "react"
import classnames from "classnames"
import { PropTypes as MobxTypes, observer } from "mobx-react"
import PropTypes from "prop-types"
import { Row, Col, Nav, NavItem } from "reactstrap"
import Button from "../Button"
import Overview from "../Overview"
import Progress from "../Progress"
import Icon from "../Icon"

import "./PropertyDetail.scss"
import { STATUS_COMPLETED, STATUS_ACTIVE } from "../../constants"

const PropertyDetail = ({
  property,
  onSwitch,
  onClone,
  onStatusToggle,
  editing
}) => {
  const status = property.status
  const progressBarIcon =
    status === STATUS_COMPLETED ? <Icon icon="check" size="md" /> : false

  return (
    <div className="property-detail">
      <div
        className={classnames("property-detail-image", {
          "property-detail-image--default": !property.imageSrc
        })}
      >
        {property.imageSrc && (
          <img src={property.imageSrc} alt={property.name} />
        )}
      </div>
      <div className="property-detail-info">
        <Row className="property-detail-info__row">
          <Col xs="12" sm="auto">
            <Progress
              className="property-detail-progress"
              percentage={
                property.percentUsed > 100
                  ? property.percentUsed - 100
                  : property.percentUsed
              }
              text={
                status === STATUS_COMPLETED ? "" : property.percentUsedFormatted
              }
              icon={progressBarIcon}
              status={property.percentUsed > 100 ? "danger" : false}
            />
          </Col>
          <Col xs="auto" className="col-fill">
            <h1 className="mb-0">{property.name}</h1>
            <p className="mb-0">
              {property.createdDate && (
                <span className="d-inline-block mr-4">
                  <strong>Started:</strong> {property.createdDate}
                </span>
              )}
              {status === STATUS_COMPLETED && (
                <span className="d-inline-block">
                  <strong>Completed:</strong> {property.completedDate}
                </span>
              )}
              {/*
              TODO Toivo https://app.asana.com/0/739444798179945/877524680579475
              <span className="d-inline-block">
                <strong>Spent:</strong> {property.spentFormatted}
              </span>
              <span className="d-inline-block">
                <strong>Budget:</strong> {property.budgetFormatted}
              </span> */}
            </p>
            <Nav className="property-detail-nav">
              {onStatusToggle && (
                <NavItem>
                  <Button
                    icon
                    onClick={onStatusToggle}
                    title={
                      status === STATUS_ACTIVE
                        ? "Mark this reno as complete"
                        : "Mark this reno as incomplete"
                    }
                  >
                    {status === STATUS_ACTIVE && (
                      <span>
                        <Icon icon="check_circle_outline" />{" "}
                        <span className="d-none d-sm-inline">Mark as </span>
                        Complete
                      </span>
                    )}
                    {status === STATUS_COMPLETED && (
                      <span>
                        <Icon icon="highlight_off" />{" "}
                        <span className="d-none d-sm-inline">Mark as </span>
                        Incomplete
                      </span>
                    )}
                  </Button>
                </NavItem>
              )}
              {onSwitch && (
                <NavItem>
                  <Button
                    icon
                    onClick={() => onSwitch(!editing)}
                    title="Edit reno details"
                  >
                    {editing && "View"}
                    {!editing && (
                      <span>
                        <Icon icon="edit" /> Edit
                      </span>
                    )}
                  </Button>
                </NavItem>
              )}
              {onClone && (
                <NavItem>
                  <Button icon onClick={onClone} title="Clone this property">
                    <span>
                      <Icon icon="layers" /> Clone
                    </span>
                  </Button>
                </NavItem>
              )}
            </Nav>
          </Col>
        </Row>
        {property.totalBudget > 0 && (
          <Overview model={property} className="mt-2" />
        )}
      </div>
    </div>
  )
}

PropertyDetail.propTypes = {
  property: MobxTypes.observableObject.isRequired,
  onStatusToggle: PropTypes.func,
  onSwitch: PropTypes.func,
  onClone: PropTypes.func,
  onDelete: PropTypes.func,
  editing: PropTypes.bool
}

export default observer(PropertyDetail)
