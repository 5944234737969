import PouchDB from "pouchdb"
import PouchDBFind from "pouchdb-find"
import PouchDBAuthentication from "pouchdb-authentication"
import PouchDBUpsert from "pouchdb-upsert"
import { invert } from "lodash/object"
import { doAction } from "./hooks"

const PRIVATE_DB = "mrt"
const { REACT_APP_DB_HOST, REACT_APP_DB_NAME } = process.env
const SHARED_LOCAL_DB = REACT_APP_DB_NAME

export const COUCH_FIELDS = {
  id: "_id",
  rev: "_rev",
  deleted: "_deleted",
  attachments: "_attachments",
}

const STATE_FIELDS = invert(COUCH_FIELDS)

export const renameCouchFields = (obj) => renameKeys(obj, COUCH_FIELDS)

export const renameForState = (doc) => renameKeys(doc, STATE_FIELDS)

const renameKeys = (obj, keys) =>
  Object.entries(obj).reduce((save, [key, value]) => {
    if (value) {
      key = keys[key] || key
      save[key] = value
    }
    return save
  }, {})

function toHex(str) {
  // utf8 to latin1
  const s = unescape(encodeURIComponent(str))
  let h = ""
  for (let i = 0; i < s.length; i++) {
    h += s.charCodeAt(i).toString(16)
  }
  return h
}

// fixes remote authentication after upgrade from pouchdb 6.4.3 to 7.0
// see https://github.com/pouchdb-community/pouchdb-authentication/issues/239
// waiting on https://github.com/pouchdb-community/pouchdb-authentication/pull/238
function remoteFetchFix(url, opts) {
  opts.credentials = "include"
  return PouchDB.fetch(url, opts)
}

PouchDB.plugin(PouchDBUpsert)
PouchDB.plugin(PouchDBFind)
PouchDB.plugin(PouchDBAuthentication)

export const privateDB = new PouchDB(PRIVATE_DB)
export const sharedDB = new PouchDB(SHARED_LOCAL_DB)
export let remoteDB = null

if (REACT_APP_DB_HOST) {
  remoteDB = new PouchDB(`${REACT_APP_DB_HOST}/${REACT_APP_DB_NAME}`, {
    fetch: remoteFetchFix,
  })
}

export default privateDB

function initSharedRemote() {
  return new Promise((resolve, reject) => {
    if (remoteDB) {
      sharedDB.replicate
        .from(remoteDB)
        .on("complete", resolve)
        .on("error", reject)
    } else {
      reject(new Error("No remote shared DB"))
    }
  })
}

export let remotePrivate = null

function initPrivateRemote(user) {
  return new Promise((resolve, reject) => {
    if (REACT_APP_DB_HOST) {
      const privateRemoteName = `${REACT_APP_DB_HOST}/userdb-${toHex(
        user.name
      )}`
      remotePrivate = new PouchDB(privateRemoteName, {
        fetch: remoteFetchFix,
      })
      privateDB.replicate
        .from(remotePrivate)
        .on("complete", resolve)
        .on("error", reject)
    } else {
      reject(new Error("No remote DB host to sync with"))
    }
  })
}

export function initRemoteDBs(user) {
  return Promise.all([initSharedRemote(), initPrivateRemote(user)])
}

let sharedSync = null
let privateSync = null

function getSyncOptions(user) {
  return {
    live: true,
    retry: true,
    filter: function (doc) {
      if (doc._deleted) {
        return false
      }
      if (doc.type === "template" || doc.type === "profit-and-loss-template") {
        return true // sync templates
      }
      if (
        doc.sharedWith &&
        Array.isArray(doc.sharedWith) &&
        doc.sharedWith.includes(user.name)
      ) {
        console.log("syncing shared", doc)
        return true
      }
      if (doc.author && doc.author !== user.name) {
        // Document wasn't authored by me
        console.log("not syncing", doc)
        return false // don't sync this
      }
      // console.log(user.name, doc)
      // return doc._id.indexOf("_design") !== 0
      return true
    },
  }
}

function initSharedSync(user) {
  if (!sharedSync) {
    sharedSync = sharedDB.sync(remoteDB, getSyncOptions(user))
    remoteDB.changes({ since: "now", live: true }).on("change", (change) => {
      if (change.deleted) {
        doAction("sync-deleted", change.id)
      } else {
        doAction("sync-change", change.id)
      }
    })
  }
}

function initPrivateSync(user) {
  if (!privateSync) {
    privateSync = privateDB.sync(remotePrivate, getSyncOptions(user))
  }
}

export function sync(user) {
  initSharedSync(user)
  initPrivateSync(user)
}

export function endSync() {
  if (sharedSync) sharedSync.cancel()
  if (privateSync) privateSync.cancel()
}
