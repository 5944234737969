import request from "./request"

const API = process.env.REACT_APP_API_URI
const headers = { "Content-Type": "application/json" }

export function createCustomer({ email, name, phone, metadata }) {
  const url = `${API}/stripe/user`
  return request(url, {
    method: "POST",
    headers,
    body: JSON.stringify({ user: { email, name, phone, metadata } })
  })
}

export function updateCustomer(id, data) {
  const url = `${API}/stripe/user/${id}`
  return request(url, {
    method: "PATCH",
    headers,
    body: JSON.stringify({ user: data })
  })
}

export function getStripePlans() {
  const url = `${API}/stripe/plans`
  return request(url)
}

export function charge(source, currency, amount, description) {
  const url = `${API}/stripe/charge`
  return request(url, {
    method: "POST",
    headers,
    body: JSON.stringify({ source, currency, amount, description })
  })
}

export function subscribe(planId, customerId, subscriptionId) {
  const url = `${API}/stripe/subscribe`
  return request(url, {
    method: "POST",
    headers,
    body: JSON.stringify({ planId, customerId, subscriptionId })
  })
}

export function getSubscription(id) {
  const url = `${API}/stripe/subscription/${id}`
  return request(url)
}
