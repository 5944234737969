import React, { Component } from "react"
import PropTypes from "prop-types"
import Button from "../Button"
import { inject } from "mobx-react"
import { addProject } from "../../routes/index"

import { ReactComponent as Plus } from "../../images/white-plus.inline.svg"

class AddProjectButton extends Component {
  static propTypes = {
    color: PropTypes.string,
    size: PropTypes.string,
    title: PropTypes.any,
    swatch: PropTypes.string
  }

  static defaultProps = {
    color: "primary",
    size: "md",
    title: (
      <span>
        <small>Add to</small>
        <br />
        Budget
      </span>
    ),
    swatch: "lg"
  }

  onClick = () => {
    const { renoTracker } = this.props
    addProject.route(renoTracker.view, {
      propertyId: renoTracker.activeProperty.id
    })
  }

  render() {
    const { renoTracker, color, size, swatch, title, ...props } = this.props

    return (
      <Button
        size={size}
        swatch={swatch}
        color={color}
        onClick={this.onClick}
        {...props}
      >
        <Plus className="btn-swatch__icon btn-swatch__icon--larger" />
        <br />
        {title}
      </Button>
    )
  }
}

export default inject("renoTracker")(AddProjectButton)
