import React, { Component } from "react"
import { observer, PropTypes as MobxTypes } from "mobx-react"
import { Form, FormGroup, Input, NavItem, Nav } from "reactstrap"
import ListItems from "../ListItems/index"
import Button from "../Button/index"
import { templatesPage } from "../../routes/index"
import Notes from "../Notes/index"
import Icon from "../Icon/index"
import NavLink from "../NavLink/index"

class TemplateForm extends Component {
  static propTypes = {
    template: MobxTypes.observableObject.isRequired
  }

  onSave = async e => {
    e.preventDefault()
    const { template } = this.props
    template.validate()
    if (template.isValid) {
      await template.save()
      template.app.notify("Template saved.")
    } else {
      template.validationFailures.map(({ message }) =>
        template.app.notify(message, { level: "warning" })
      )
    }
  }

  onDelete = async () => {
    const sure = window.confirm(
      "Are you sure you want to delete this template?"
    )
    const { template } = this.props
    if (sure) {
      await template.delete()
      templatesPage.route(template.viewer)
    }
  }

  addItem = () => this.props.template.addItem()

  deleteItem = item => {
    if (window.confirm("Are you sure you want to remove this item?")) {
      this.props.template.deleteItem(item.id)
    }
  }

  setName = ({ target: { value } }) => this.props.template.setName(value)

  render() {
    const { template } = this.props
    return (
      <div>
        <Form>
          <FormGroup>
            {/* <Label htmlFor="name">Name</Label> */}
            <h4>
              <Input
                type="text"
                id="name"
                value={template.name}
                onChange={this.setName}
                placeholder="Template Name"
              />
            </h4>
            <Nav className="property-detail-nav">
              <NavItem className="ml-auto">
                <Button
                  icon
                  onClick={this.onDelete}
                  title="Delete this category and all of it's items"
                  className="text-danger"
                >
                  <Icon icon="delete" /> Delete Template
                </Button>
              </NavItem>
            </Nav>
          </FormGroup>
        </Form>
        <div className="my-4">
          <ListItems
            items={template.allItems}
            onDelete={this.deleteItem}
            onAdd={this.addItem}
            displayStatusToggle
          />
        </div>
        <Notes node={template} />
        <Form>
          <Nav className="justify-content-center align-items-center nav-space-items">
            <NavItem>
              <NavLink href={templatesPage.url()}>
                <Button swatch color="secondary">
                  Back
                </Button>
              </NavLink>
            </NavItem>
            <NavItem>
              <Button color="primary" swatch onClick={this.onSave}>
                Save
              </Button>
            </NavItem>
          </Nav>
        </Form>
      </div>
    )
  }
}

export default observer(TemplateForm)
