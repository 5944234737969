import { types } from "mobx-state-tree"
import Item from "./Item"
import { generate } from "../utils/uuid"
import { STATUS_ITEM_SUGGESTED, STATUS_ACTIVE } from "../constants"

const ItemList = types
  .model({
    items: types.optional(types.map(Item), {}),
    name: "",
    notes: "",
    scopeOfWork: "",
    sort: types.optional(
      types.enumeration(["name", "budget", "spent", "available"]),
      "name"
    ),
    sortMode: types.optional(types.enumeration(["asc", "desc"]), "asc")
  })
  .views(self => ({
    get activeItems() {
      return self.allItems
        .filter(i => i.status === STATUS_ACTIVE)
        .sort((itemA, itemB) => {
          const valueA = itemA[self.sort]
          const valueB = itemB[self.sort]
          if (typeof valueA === "string") {
            const a = valueA.toUpperCase()
            const b = valueB.toUpperCase()
            if (a < b) return self.sortMode === "asc" ? -1 : 1
            if (a > b) return self.sortMode === "asc" ? 1 : -1
            return 0
          }
          return self.sortMode === "asc" ? valueA - valueB : valueB - valueA
        })
    },
    get suggestedItems() {
      return self.allItems.filter(i => i.status === STATUS_ITEM_SUGGESTED)
    },
    get allItems() {
      return Array.from(self.items.values())
    },
    sumField(field) {
      // Logic here needs to reflected in couch views
      return self.activeItems.reduce((sum, item) => (sum += item[field]), 0)
    }
  }))
  .actions(self => ({
    addItem(item) {
      if (!item) {
        item = Item.create({ id: generate() })
      }
      self.items.put(item)
    },
    deleteItem(id) {
      self.items.delete(id)
    },
    setName(value) {
      self.name = value
    },
    setNotes(value) {
      self.notes = value
    },
    setScopeOfWork(value) {
      self.scopeOfWork = value
    },
    setSort(field, mode) {
      self.sort = field
      self.sortMode = mode ? mode : self.sortMode === "asc" ? "desc" : "asc"
    }
  }))

export default ItemList
