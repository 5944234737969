import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import Textarea from "react-textarea-autosize"

import "./Textarea.scss"

const Input = ({
  wrapperClassName,
  className,
  onChange,
  label,
  id,
  name,
  help,
  autosize,
  node,
  hasMinHeight,
  ...props
}) => {
  const htmlId = id || name

  return (
    <div className={wrapperClassName}>
      {label && <label htmlFor={htmlId}>{label}</label>}

      {autosize && (
        <Textarea
          id={htmlId}
          className={classNames(
            className,
            "form-control",
            "textarea-autosize",
            { "textarea-autosize--has-min-height": hasMinHeight }
          )}
          onChange={({ target: { value } }) => onChange(value, node)}
          {...props}
        />
      )}

      {!autosize && (
        <textarea
          id={htmlId}
          className={classNames("form-control", className)}
          onChange={({ target: { value } }) => onChange(value, node)}
          {...props}
        />
      )}

      {help && help}
    </div>
  )
}

Input.propTypes = {
  wrapperClassName: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string
}

export default Input
