import { observer, PropTypes as MobxTypes } from "mobx-react"
import React, { Component } from "react"
import classnames from "classnames"
import PropTypes from "prop-types"
import {
  Input,
  InputGroup,
  InputGroupAddon,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap"
import Icon from "../Icon"
import Button from "../Button"
import Textarea from "../Textarea"

class ListItem extends Component {
  // const ListItem = observer(({ item, onDelete, onMove, displayStatusToggle }) => (
  static propTypes = {
    item: MobxTypes.observableObject.isRequired,
    onDelete: PropTypes.func.isRequired,
    defaultItemExpanded: PropTypes.bool,
    // onMove: PropTypes.func, //.isRequired,
    onChange: PropTypes.func,
    displayStatusToggle: PropTypes.bool
  }

  constructor(props) {
    super(props)

    this.state = {
      showItemActionsDropdown: false,
      itemExpanded: !!props.defaultItemExpanded
    }
  }

  doChange = fn => {
    const { onChange } = this.props
    return (...args) => {
      fn.apply(undefined, args)
      if (onChange) {
        onChange()
      }
    }
  }

  toggleItemActionsDropdown = () => {
    this.setState({
      showItemActionsDropdown: !this.state.showItemActionsDropdown
    })
  }

  toggleItemExpanded = () => {
    this.setState({ itemExpanded: !this.state.itemExpanded })
  }

  renderActions = item => (
    <span className="nowrap">
      {this.props.displayStatusToggle && (
        <Button
          color="secondary"
          size="sm"
          onClick={() => item.toggleSuggested()}
          title={
            item.isSuggested
              ? "Switch item to default"
              : "Switch item to suggested"
          }
        >
          <Icon icon={item.isSuggested ? "help_outline" : "check_circle"} />
          <span className="budget-item-bit__mob-expanded-only-inlineblock">
            {" "}
            {item.isSuggested
              ? "Switch item to default"
              : "Switch item to suggested"}
          </span>
        </Button>
      )}
      <Button
        className="budget-item-bit__mob-expanded-only-inlineblock text-danger"
        color="secondary"
        size="sm"
        onClick={() => this.props.onDelete(item)}
      >
        <Icon icon="remove_circle_outline" /> Remove item
      </Button>
      {item.suggestedBudgetFormated && (
        <p className="budget-item-bit__mob-expanded-only-inlineblock suggested-text">
          (Suggested: {item.suggestedBudgetFormated})
        </p>
      )}
      {/* {onMove && 
      <Dropdown
        isOpen={this.state.showMoveCatDropdown}
        toggle={this.toggleMoveCatDropdown}
      >
        <DropdownToggle
          className="btn-match-input btn-none"
          title="Move to another category"
        >
          <Icon icon="import_export" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu--overscroll">
          <DropdownItem header>Select category to move to:</DropdownItem>
          <DropdownItem onClick={() => onMove(item)}>
            Category 1
          </DropdownItem>
          <DropdownItem onClick={() => onMove(item)}>
            Category 2
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
      } */}
      <Dropdown
        isOpen={this.state.showItemActionsDropdown}
        toggle={this.toggleItemActionsDropdown}
        className="d-none d-md-inline-block"
      >
        <DropdownToggle
          className="btn-match-input btn-none"
          title="Item actions"
        >
          <Icon icon="more_vert" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu--overscroll">
          {item.suggestedBudgetFormated && (
            <DropdownItem>
              <span className="suggested-text">
                Suggested:{" "}
                <span className="suggested-amount">
                  {item.suggestedBudgetFormated}
                </span>
              </span>
            </DropdownItem>
          )}
          <DropdownItem onClick={() => this.props.onDelete(item)}>
            <Icon icon="remove_circle_outline" className="text-danger" /> Remove
            item
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </span>
  )

  render() {
    const { item } = this.props
    return (
      <tr
        className={classnames("budget-item-row", "budget-item-row--default", {
          "row-accentuated": item.isSuggested,
          "is-expanded": this.state.itemExpanded
        })}
      >
        <td className="budget-item-row__description">
          <div
            onClick={this.toggleItemExpanded}
            className="match-input budget-item-bit__mob-show-for-not-expanded w-100"
          >
            {item.name || "New Item"}
          </div>
          <Textarea
            wrapperClassName="budget-item-row__description-field budget-item-bit__mob-show-for-expanded"
            autosize
            defaultValue={item.name}
            node={item}
            onChange={this.doChange((value, node) => node.setName(value))}
            placeholder="Enter item description"
          />
          <span className="d-md-none d-inline-flex">
            <span className="pl-2">
              <label htmlFor="" className="d-block text-right line-height-1">
                <small>Left</small>
              </label>
              <div className="nowrap">{item.availableFormatted || "-"}</div>
            </span>
            <Button
              color="none"
              size="sm"
              onClick={this.toggleItemExpanded}
              className="btn-circle p-0 ml-2"
            >
              <Icon
                icon={this.state.itemExpanded ? "expand_less" : "expand_more"}
                size="md"
              />
            </Button>
          </span>
        </td>
        <td className="text-right budget-item-row__quantity budget-item-bit__mob-show-for-expanded">
          <label htmlFor="" className="budget-item-row__label text-center">
            Quantity
          </label>
          <InputGroup className="flex-nowrap">
            <InputGroupAddon addonType="prepend">
              <Button
                type="button"
                size="xs"
                className="btn-icon pl-0"
                title="Decrease quantity"
                onClick={this.doChange(() =>
                  item.setQuantity(item.quantity - 1)
                )}
              >
                <Icon icon="remove" />
              </Button>
            </InputGroupAddon>
            <Input
              type="number"
              min="0"
              className="minw-3 form-control-no-browser-helpers text-center"
              value={Math.max(item.quantity || 1)}
              onChange={this.doChange(({ target: { value } }) =>
                item.setQuantity(value)
              )}
            />
            <InputGroupAddon addonType="append">
              <Button
                size="xs"
                type="button"
                className="btn-icon pr-0"
                title="Increase quantity"
                onClick={this.doChange(() =>
                  item.setQuantity(item.quantity + 1)
                )}
              >
                <Icon icon="add" />
              </Button>
            </InputGroupAddon>
          </InputGroup>
        </td>
        <td className="text-right budget-item-row__budget budget-item-bit__mob-show-for-expanded">
          <label htmlFor="" className="budget-item-row__label">
            Budget
          </label>
          <Input
            // type="number" NOTE this screws out as currently we are formatting the number fancy with dots and $ symbol which number input doesn't like
            type="tel"
            className="form-control-no-browser-helpers"
            value={item.budgetFormatted === "$0" ? "" : item.budgetFormatted}
            onChange={this.doChange(({ target: { value } }) =>
              item.setBudget(value)
            )}
            placeholder="$0"
          />
        </td>
        <td className="text-right budget-item-row__spent budget-item-bit__mob-show-for-expanded">
          <label htmlFor="" className="budget-item-row__label">
            Spent
          </label>
          <Input
            type="tel"
            className="form-control-no-browser-helpers"
            value={item.spentFormatted === "$0" ? "" : item.spentFormatted}
            onChange={this.doChange(({ target: { value } }) =>
              item.setSpent(value)
            )}
            placeholder="$0"
          />
        </td>
        <td className="text-right budget-item-row__available">
          <label htmlFor="" className="budget-item-row__label">
            Left
          </label>
          <div className="match-input">{item.availableFormatted}</div>
        </td>
        <td className="budget-item-row__actions budget-item-bit__mob-show-for-expanded mt-2 mt-md-0">
          {this.renderActions(item)}
        </td>
      </tr>
    )
  }
}

export default observer(ListItem)
