import { types } from "mobx-state-tree"
import { remoteDB } from "../utils/db"
import { flow } from "mobx"
import { createCustomer, subscribe } from "../utils/stripe"

function getUser(name) {
  return new Promise((resolve, reject) => {
    remoteDB.getUser(name, (err, res) => {
      if (err) return reject(err)
      return resolve(res)
    })
  })
}

function putUser(name, metadata) {
  return new Promise((resolve, reject) => {
    remoteDB.putUser(name, { metadata }, (err, res) => {
      if (err) return reject(err)
      return resolve(res)
    })
  })
}

const User = types
  .model("User", {
    name: types.identifier,
    roles: types.optional(types.array(types.string), []),
    metadata: types.optional(types.map(types.frozen()), {})
  })
  .views(self => ({
    get isAuthenticated() {
      return !!self.name
    },
    get isAdmin() {
      return self.roles.includes("admin") || self.roles.includes("_admin")
    },
    get avatar() {
      return self.getMeta("avatar")
    },
    get hasAvatar() {
      return !!self.avatar
    },
    get hasPlan() {
      return !!self.getMeta("stripeSubscriptionId")
    },
    getMeta(key) {
      return self.metadata.get(key)
    }
  }))
  .actions(self => ({
    setMeta(key, value) {
      return self.metadata.set(key, value)
    },

    setAvatar(value) {
      return self.setMeta("avatar", value)
    },

    update() {
      return putUser(self.name, { metadata: self.metadata.toJSON() })
    },

    setMetadata(metadata) {
      self.metadata = metadata
    },

    load: flow(function*() {
      const user = yield getUser(self.name)
      // self.metadata = user.metadata // doesn't work for some reason ?
      self.setMetadata(user.metadata)
      return self
    }),

    saveToStripe: flow(function*() {
      const stripeCustomer = yield createCustomer({
        email: self.name,
        name: self.getMeta("fullName"),
        phone: self.getMeta("phone")
      })
      self.setMeta("stripeId", stripeCustomer.id)
      yield self.update()
      return stripeCustomer
    }),

    stripeSubscribe: flow(function*(planId, productId) {
      if (!self.getMeta("stripeId")) {
        yield self.saveToStripe()
      }
      const subscription = yield subscribe(
        planId,
        self.getMeta("stripeId"),
        self.getMeta("stripeSubscriptionId")
      )
      self.setMeta("stripeProductId", productId)
      self.setMeta("stripePlanId", planId)
      self.setMeta("stripeSubscriptionId", subscription.id)
      yield self.update()
      return subscription
    })
  }))

export default User
