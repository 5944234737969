import { types, getSnapshot } from "mobx-state-tree"
import { TYPE_PROFIT_AND_LOSS_TEMPLATE } from "../constants"
import ProfitAndLoss from "./ProfitAndLoss"
import { sharedDB } from "../utils/db"
import Document from "./Document"
import PriceField from "./PriceField"
// import { toNumber } from "lodash/lang"

export const ID = "profit-and-loss-template"

function toTemplateID(id) {
  return `${id}-template`
}

const ProfitAndLossTemplate = types.compose(
  "ProfitAndLossTemplate",
  ProfitAndLoss,
  Document,
  types
    .model({
      id: ID,
      type: TYPE_PROFIT_AND_LOSS_TEMPLATE
      // fields: types.optional(types.map(PriceField), {})
    })
    .views(self => ({
      get db() {
        // because templates write to the shared db, not the private db
        return sharedDB
      },
      hasField(field) {
        return self.fields.has(toTemplateID(field.id))
      },
      getForField(field) {
        if (self.hasField(field)) {
          return self.fields.get(toTemplateID(field.id))
        }
      }
    }))
    .actions(self => ({
      saveField(field) {
        const newField = PriceField.create({
          ...getSnapshot(field),
          id: toTemplateID(field.id)
        })
        self.fields.put(newField)
        self.save()
      }
    }))
)

export default ProfitAndLossTemplate
