import React from "react"
import { inject } from "mobx-react"
import { home } from "../../routes/index"
import Link from "../Link/index"
import { Card, CardBody } from "reactstrap"

const PageNotFound = () => (
  <Card className="card-raised text-center mw-40 my-5 mx-auto">
    <CardBody>
      <h1>Dang it!</h1>
      <p>
        There's nothing here...{" "}
        <strong>
          <Link to={home.url()}>Head back home</Link>
        </strong>{" "}
        and try something else.
      </p>
    </CardBody>
  </Card>
)

export default inject("viewer")(PageNotFound)
