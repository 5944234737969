import React, { Component } from "react"
import { inject, observer } from "mobx-react"
import {
  Nav,
  NavItem,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap"
import Link from "../Link"
import { logout, templatesPage, selectAvatar } from "../../routes/index"
import Avatar from "../Avatar/index"
import Icon from "../Icon"
import { ReactComponent as Logo } from "../../images/my-properties-square-white.inline.svg"

import "./appbar.scss"

class AppBar extends Component {
  state = { showUserDropdown: false }

  toggleUserDropdown = () => {
    this.setState({ showUserDropdown: !this.state.showUserDropdown })
  }

  render() {
    const { renoTracker } = this.props

    return (
      <div className="appbar">
        <Nav className="align-items-end">
          <NavItem className="appbar__nav-item">
            <Link to="/" className="appbar__apptitle">
              <Logo className="appbar__logo mr-2" />
              <span className="h4 mb-0 mt-4">RenoTracker</span>
            </Link>
          </NavItem>

          <NavItem className="appbar__nav-item ml-auto">
            {/* left / right divider */}
          </NavItem>

          {renoTracker.user.isAdmin && (
            <NavItem className="appbar__nav-item d-none d-md-block pb-1">
              <Link to="/">My Properties</Link>
            </NavItem>
          )}
          {renoTracker.user.isAdmin && (
            <NavItem className="appbar__nav-item d-none d-md-block pb-1">
              <Link to={templatesPage.url()}>Templates</Link>
            </NavItem>
          )}
          {renoTracker.user.avatar && (
            <NavItem className="appbar__nav-item">
              <Dropdown
                isOpen={this.state.showUserDropdown}
                toggle={this.toggleUserDropdown}
                direction="right"
              >
                <DropdownToggle
                  className="btn-none p-0"
                  title="Move to another category"
                >
                  <Avatar
                    className="d-inline-block"
                    size="small"
                    id={renoTracker.user.avatar}
                  />
                  <Icon icon="more_vert" size="md" />
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-right">
                  {renoTracker.user.isAdmin && (
                    <DropdownItem className="d-block d-md-none">
                      <Link to="/">My Properties</Link>
                    </DropdownItem>
                  )}
                  {renoTracker.user.isAdmin && (
                    <DropdownItem className="d-block d-md-none">
                      <Link to={templatesPage.url()}>Templates</Link>
                    </DropdownItem>
                  )}
                  <DropdownItem>
                    <Link to={selectAvatar.url()}>Change my avatar</Link>
                  </DropdownItem>
                  {/* <DropdownItem>
                    <Link to={plans.url()}>Change my plan</Link>
                  </DropdownItem> */}
                  <DropdownItem>
                    <Link to={logout.url()}>Logout</Link>
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </NavItem>
          )}
        </Nav>
      </div>
    )
  }
}

export default inject("renoTracker")(observer(AppBar))
