import { observer } from "mobx-react"
import { Instance } from "mobx-state-tree"
import React from "react"
import RenoTracker from "../../models/RenoTracker"
import { LOGIN } from "../../models/ViewStore"
import { applyFilters } from "../../utils/hooks"
import BlankLayout from "../Layouts/Blank"
import DefaultLayout from "../Layouts/Default"
import Loading from "../Loading"
import PageNotFound from "../PageNotFound"
import "./app.scss"

interface AppProps {
  renoTracker: Instance<typeof RenoTracker>
}

const App: React.FunctionComponent<AppProps> = ({ renoTracker }) => {
  return renoTracker.isAuthenticated && renoTracker.view.page !== LOGIN ? (
    <DefaultLayout renoTracker={renoTracker}>
      {renderApp(renoTracker)}
    </DefaultLayout>
  ) : (
    <BlankLayout renoTracker={renoTracker}>
      {renderApp(renoTracker)}
    </BlankLayout>
  )
}

function renderApp(renoTracker: any) {
  if (!renoTracker.view.routeFound) {
    return <PageNotFound />
  }
  if (renoTracker.isLoading) {
    return <Loading />
  }
  const Component = applyFilters("App.renderRoute", null, renoTracker.view)
  if (Component) {
    return Component
  } else {
    return <PageNotFound />
  }
}

export default observer(App)
