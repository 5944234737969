import React, { Component } from "react"
import { get } from "lodash/object"
import { inject, observer } from "mobx-react"
import { editProperty, propertyPage, home } from "../../routes/index"
import Property from "../Property"
import { STATUS_ACTIVE, STATUS_COMPLETED } from "../../constants"

class PropertyPage extends Component {
  onSwitch = edit => {
    const { property, viewer } = this.props
    edit
      ? editProperty.route(viewer, { id: property.id })
      : propertyPage.route(viewer, { id: property.id })
  }

  onClone = async () => {
    const { property, viewer } = this.props
    const clone = await property.clone({ name: `${property.name} Clone` })
    propertyPage.route(viewer, { id: clone.id })
  }

  onDelete = async () => {
    const { viewer, property } = this.props
    if (window.confirm("Are you sure you want to delete this property?")) {
      home.route(viewer)
      viewer.deleteProperty({ propertyId: property.id })
    }
  }

  onStatusToggle = () => {
    const { property } = this.props
    const newStatus =
      property.status === STATUS_ACTIVE ? STATUS_COMPLETED : STATUS_ACTIVE
    property.setStatus(newStatus)
    property.save()
  }

  render() {
    const { property, viewer, defaultTab } = this.props
    const editing = get(viewer, ["params", "edit"], false)
    return (
      <div>
        <Property
          property={property}
          defaultTab={defaultTab}
          editing={editing}
          onStatusToggle={this.onStatusToggle}
          onSwitch={this.onSwitch}
          onDelete={this.onDelete}
          onClone={this.onClone}
        />
      </div>
    )
  }
}

export default inject("viewer")(observer(PropertyPage))
