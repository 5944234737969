import React from "react"
import PropTypes from "prop-types"
import { observer, PropTypes as MobxTypes } from "mobx-react"
import { format } from "../../utils/price"
import { ACQUISITION } from "../../models/PriceField"
import FieldRow from "./FieldRow"
import AddCustomField from "./AddCustomField"
import { Label } from "reactstrap"

const Acquisition = observer(
  ({ profitAndLoss, onChange, onChangeName, onRemove }) => (
    <div>
      <table className="list-items list-items--transformer table-borderless table-headed table table-sm">
        <thead className="list-items__head">
          <tr className="list-items__head-row">
            <td
              // style={{ width: "70%" }}
              className="list-items__head-tradeDescription"
            >
              <h5>Items</h5>
            </td>
            <td className="text-right minw-5 list-items__head-tradeBudget">
              <Label>Value</Label>
            </td>
            <td className="text-right list-items__head-actions">
              {/* Actions */}
            </td>
          </tr>
        </thead>
        <tbody className="list-items__body">
          {profitAndLoss.acquisitionFields.map(field => (
            <FieldRow
              key={field.id}
              field={field}
              onChange={onChange}
              onRemove={onRemove}
            />
          ))}
          {profitAndLoss.customAcquisitionFields.map((field, idx) => (
            <FieldRow
              key={`${field.id}-${idx}`}
              field={field}
              onChange={onChange}
              onChangeName={onChangeName}
              onRemove={onRemove}
            />
          ))}
          <AddCustomField profitAndLoss={profitAndLoss} type={ACQUISITION} />
        </tbody>
      </table>
      <table className="list-items table-accentuated table-borderless table-headed table table-sm">
        <tbody>
          <tr>
            <td>Total Acquisition Costs (GST Inclusive)</td>
            <td className="text-right">
              {format(profitAndLoss.acquisitionTotal)}
            </td>
          </tr>
          <tr>
            <td>Less GST</td>
            <td className="text-right">
              {format(profitAndLoss.acquisitionGST)}
            </td>
          </tr>
          <tr>
            <td>Net Acquisition Cost (GST Exclusive)</td>
            <td className="text-right">
              {format(profitAndLoss.acquisitionNet)}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
)

Acquisition.propTypes = {
  profitAndLoss: MobxTypes.observableObject.isRequired,
  onChange: PropTypes.func.isRequired,
  onChangeName: PropTypes.func,
  onRemove: PropTypes.func
}

export default Acquisition
