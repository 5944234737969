import React from "react"
import classNames from "classnames"
import PropTypes from "prop-types"

import { CircularProgressbar } from "react-circular-progressbar"
import "react-circular-progressbar/dist/styles.css"
import "./Progress.scss"

const Progress = ({
  withBG,
  className,
  style = "circular",
  icon,
  status,
  ...props
}) => (
  <span
    className={classNames(className, "progress-wrapper", {
      "progress-wrapper--circular": style === "circular",
      "progress-wrapper--with-bg": withBG,
      "progress-wrapper--danger": status === "danger",
    })}
  >
    {style === "circular" && <CircularProgressbar {...props} />}
    {icon && <span className="progress__icon">{icon}</span>}
  </span>
)

Progress.propTypes = {
  style: PropTypes.string,
}

export default Progress
