import {
  types,
  flow,
  getSnapshot,
  destroy,
  applySnapshot
} from "mobx-state-tree"
import { omit } from "lodash/object"
import { sortBy } from "lodash/collection"
import { uniqBy } from "lodash/array"
import Property from "./Property"
import Template from "./Template"
import User from "./User"
import Document from "./Document"
import ViewStore from "./ViewStore"
import ProfitAndLossTemplate, { ID as PAL_ID } from "./ProfitAndLossTemplate"
import {
  TYPE_RENOTRACKER,
  STATUS_ACTIVE,
  STATUS_COMPLETED,
  TYPE_TEMPLATE,
  STATUS_DRAFT
} from "../constants"
import { generate } from "../utils/uuid"
import db, {
  remoteDB,
  sharedDB,
  sync,
  endSync,
  initRemoteDBs
} from "../utils/db"
import { propertySpent, propertyBudget } from "../utils/couchViews"

const RenoTracker = types.compose(
  "RenoTracker",
  Document,
  types
    .model({
      id: types.maybeNull(types.string), // users email
      properties: types.optional(types.map(Property), {}),
      propertyIds: types.optional(types.array(types.string), []),
      templates: types.optional(types.map(Template), {}),
      user: types.maybeNull(User),
      view: types.optional(ViewStore, {}),
      loading: false,
      loaded: false,
      type: TYPE_RENOTRACKER,
      notifications: types.optional(types.array(types.frozen()), []),
      session: types.maybeNull(types.map(types.frozen())),
      profitAndLossTemplate: types.maybeNull(ProfitAndLossTemplate)
    })
    .views(self => {
      const superGetCouchFields = self.getCouchFields

      return {
        get isAuthenticated() {
          return !!(self.user && self.user.isAuthenticated)
        },
        get activeProperties() {
          return Array.from(self.properties.values()).filter(
            p => !p.deleted && p.status === STATUS_ACTIVE
          )
        },
        get completedProperties() {
          return Array.from(self.properties.values()).filter(
            p => !p.deleted && p.status === STATUS_COMPLETED
          )
        },
        get activeTemplates() {
          return sortBy(
            Array.from(self.templates.values()).filter(t => !t.deleted),
            ["name"]
          )
        },
        get hasProperties() {
          return self.properties.size > 0
        },
        get activeProperty() {
          return self.view.activeProperty
        },
        getCouchFields() {
          const fields = superGetCouchFields()
          const save = {
            ...omit(fields, [
              "view",
              "loading",
              "loaded",
              "user",
              "templates",
              "session",
              "notifications"
            ]),
            _id: self.user.name,
            properties: Object.entries(fields.properties).reduce(
              (properties, [propertyId, property]) => {
                if (!self.propertyIds.includes(propertyId)) {
                  return {
                    ...properties,
                    // Don't save properties projects here,
                    // these will go into a separate document
                    // TODO this logic should move to property
                    [propertyId]: omit(property, [
                      "projects",
                      "rev",
                      "attachments",
                      "blobs",
                      "searchMatchedItemIds",
                      "activeProject"
                    ])
                  }
                }
                return properties
              },
              {}
            )
          }
          return save
        },
        get uniqueNotifications() {
          // return self.notifications
          return uniqBy(self.notifications, "message")
        },
        get draftProperty() {
          return Array.from(self.properties.values()).find(
            p => !p.deleted && p.status === STATUS_DRAFT
          )
        },
        get isLoading() {
          return self.loading || !self.view.routeReady
        },
        getOrLoadTemplate(id) {
          const template = self.templates.get(id)
          if (!template) {
            setImmediate(() => self.loadTemplate(id))
          }
          return template
        },
        getOrLoadPALTemplate() {
          if (!self.profitAndLossTemplate) {
            setImmediate(() => self.loadPALTemplate())
          }
          return self.profitAndLossTemplate
        }
      }
    })
    .actions(self => {
      const superLoad = self.load

      const addProperty = property => {
        // self.propertyIds = [...new Set(self.propertyIds.concat(property.id))]
        if (!self.properties.has(property.id)) {
          return self.properties.put(property)
        }
        return property
      }

      const addPropertyId = propertyId => {
        self.propertyIds = [...new Set(self.propertyIds.concat(propertyId))]
      }

      const getProperty = id => {
        return self.properties.get(id)
      }

      const getSession = flow(function*() {
        if (!self.session || !self.session.get("userCtx").name) {
          const session = yield remoteDB.getSession()
          if (!session.userCtx.name) {
            yield self.logout()
            self.session = null
          } else {
            self.session = session
          }
        }
        return self.session
      })

      const isLoggedIn = flow(function*() {
        const session = yield getSession()
        return !!session
      })

      const load = flow(function*(id) {
        const res = yield superLoad(id)
        yield self.loadProperties()
        return res
      })

      const loadUser = flow(function*() {
        if (!self.user) {
          const session = yield getSession()
          if (session && session.has("userCtx")) {
            self.user = User.create(session.get("userCtx"))
            yield initRemoteDBs(self.user)
            yield self.user.load()
            sync(self.user)
          }
        }
        return self.user
      })

      const loadForUser = flow(function*({ reload = false } = {}) {
        self.loading = true
        const user = yield loadUser()
        try {
          if (user && user.name && (!self.loaded || reload)) {
            yield self.load(user.name)
            self.loaded = true
          }
          self.loading = false
          return user
        } catch (e) {
          self.loading = false
          if (e.status === 404) {
            self.loaded = true
            console.log("nothing saved yet")
            return user
          } else {
            console.log("uncaught loadForUser error")
            console.error(e)
            throw e
          }
        }
      })

      const authenticate = flow(function*(email, password) {
        email = email.toLowerCase()
        yield remoteDB.login(email, password)
        return loadUser()
      })

      const logout = flow(function*() {
        endSync()
        yield remoteDB.logOut()
        applySnapshot(self, {})
      })

      const signUp = flow(function*({ email, ...metadata }) {
        email = email.toLowerCase().trim()
        const password = generate()
        const options = { metadata: { metadata } }
        yield remoteDB.signUp(email, password, options)
        const user = yield authenticate(email, password)
        yield user.saveToStripe()
        return user
      })

      const notify = (message, { level = "info", timeout = 5 } = {}) => {
        self.notifications.push({ message, level })
        if (timeout) {
          setTimeout(() => self.clearNotification(message), timeout * 1000)
        }
      }

      const loadPALTemplate = flow(function*() {
        try {
          self.profitAndLossTemplate = yield sharedDB.get(PAL_ID)
        } catch (e) {
          if (e.status === 404) {
            console.warn("Can't find P&L template, will create")
            self.profitAndLossTemplate = ProfitAndLossTemplate.create({})
          } else {
            throw e
          }
        }
      })

      const loadProperties = ids => {
        const loadIds = ids || self.propertyIds
        return Promise.all(
          loadIds
            .filter(id => !self.properties.has(id))
            .map(id => {
              // console.log("loading property", id)
              self.properties.put({ id })
              return self.properties
                .get(id)
                .load()
                .catch(e => {
                  if (e.status === 404) {
                    console.warn(`Can't find property: ${id}`)
                  } else throw e
                })
            })
        )
      }

      const loadTemplates = flow(function*(ids) {
        if (!ids) {
          const res = yield sharedDB.find({
            selector: {
              type: TYPE_TEMPLATE
            },
            fields: ["_id"]
          })
          ids = res.docs.map(doc => doc._id)
        }
        return Promise.all(
          ids
            .filter(id => !self.templates.has(id))
            .map(id => {
              // console.log("loading template", id)
              self.templates.put({ id })
              return self.templates
                .get(id)
                .load()
                .catch(e => {
                  if (e.status === 404) {
                    console.warn(`Can't find template: ${id}`)
                  } else throw e
                })
            })
        )
      })

      const loadTemplate = flow(function*(id) {
        yield self.loadTemplates([id])
        return self.templates.get(id)
      })

      const addTemplate = template => {
        self.templates.put(template)
      }

      const clearNotification = message => {
        self.notifications = self.notifications.filter(
          ({ message: m }) => message !== m
        )
      }

      const deleteProperty = flow(function*(id) {
        if (self.properties.has(id)) {
          destroy(self.properties.get(id))
          self.propertyIds = self.propertyIds.filter(pid => pid !== id)
        }
        yield self.save()
        yield self.load()
      })

      const superSave = self.save
      const save = () => {
        const update = !!self.id
        return superSave(update)
      }

      const superConvertCouchFields = self.convertCouchFields
      const convertCouchFields = doc => {
        const snapshot = superConvertCouchFields(doc)
        const res = {
          ...snapshot,
          user: getSnapshot(self.user)
        }
        return res
      }

      const setPropertyPercentValues = flow(function*() {
        const [spentView, budgetView] = yield Promise.all([
          propertySpent(),
          propertyBudget()
        ])
        const queryOptions = { group_level: 1 }
        const [spentRes, budgetRes] = yield Promise.all([
          db.query(spentView, queryOptions),
          db.query(budgetView, queryOptions)
        ])
        const flatten = (values, row) => ({
          ...values,
          [row.key[0]]: row.value
        })
        const spentValues = spentRes.rows.reduce(flatten, {})
        const budgetValues = budgetRes.rows.reduce(flatten, {})
        ;[...self.properties.keys()].forEach(key => {
          const spent = spentValues[key] || 0
          const budget = budgetValues[key] || 0
          if (budget) {
            const percent = spent / budget
            self.properties.get(key).setPercent(percent)
          }
        })
      })

      return {
        addProperty,
        addPropertyId,
        addTemplate,
        authenticate,
        clearNotification,
        convertCouchFields,
        deleteProperty,
        getProperty,
        isLoggedIn,
        load,
        loadForUser,
        loadPALTemplate,
        loadProperties,
        loadTemplate,
        loadTemplates,
        logout,
        notify,
        save,
        setPropertyPercentValues,
        signUp
      }
    })
)

export default RenoTracker
