import React from "react"
import classnames from "classnames"
import { Row, Col } from "reactstrap"
import { observer, inject } from "mobx-react"
import AddProjectButton from "../AddProjectButton"
import ProjectCard from "../ProjectCard"

import "./ListProjectsGrid.scss"

const ListProjectsGrid = ({ projects }) => {
  const hasProjects = projects.length > 0

  return (
    <span>
      {!hasProjects && (
        <div className="text-center">
          <h4>Welcome to your reno!</h4>
          <p>
            To get started, add some categories and items to your budget using
            the button below...
          </p>
        </div>
      )}
      <Row
        className={classnames("row-space-items", {
          "justify-content-center": !hasProjects
        })}
      >
        <Col
          xs="6"
          sm="6"
          md="4"
          lg="3"
          className="align-items-center d-flex justify-content-center"
        >
          <AddProjectButton className="project-list-add" />
        </Col>

        {projects.map(project => (
          <Col key={project.id} xs="6" sm="6" md="4" lg="3">
            <ProjectCard project={project} />
          </Col>
        ))}
      </Row>
    </span>
  )
}

export default inject("renoTracker")(observer(ListProjectsGrid))
