import React from "react"
import { observer } from "mobx-react"
import { Card, CardBody } from "reactstrap"
import TemplateForm from "../TemplateForm/index"

const TemplatePage = ({ template }) => {
  return (
    <span>
      <div className="app-layout__view-inner">
        <Card className="card-raised mw-50 m-auto">
          {/* <CardHeader>
            <CardTitle>{template.name}</CardTitle>
          </CardHeader> */}
          <CardBody>
            <TemplateForm template={template} />
          </CardBody>
        </Card>
      </div>
    </span>
  )
}

export default observer(TemplatePage)
