import React, { useState } from "react"
import PropTypes from "prop-types"
import {
  Input,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap"
import Icon from "../Icon"
import { fieldDefaults } from "../../models/ProfitAndLoss"

function getFieldDefault(field, property) {
  if (field.id in fieldDefaults) {
    return fieldDefaults[field.id][property]
  }
}

function FieldRow({ field, onChange, onChangeName, onRemove }) {
  const [showActions, setShowActions] = useState(false)
  const isAdmin = field.app.user.isAdmin
  const withRemove = getFieldDefault(field, "removable") !== false && onRemove
  const withMenu = isAdmin || withRemove
  return (
    <tr className="budget-item-row budget-item-row--default">
      <td className="budget-item-row__tradeDescription budget-item-bit__mob-show-for-expanded2 ">
        <div>
          {!onChangeName ? (
            field.label
          ) : (
            <Input
              className="form-control-no-browser-helpers"
              type="text"
              name={field.id}
              value={field.name}
              onChange={onChangeName}
              style={{ width: "50%", display: "inline-block" }}
            />
          )}
        </div>
      </td>
      <td className="text-right budget-item-row__tradeBudget budget-item-bit__mob-show-for-expandedNOT ">
        <Input
          type="tel"
          name={field.id}
          className="form-control-no-browser-helpers"
          value={field.formated === "$0" ? "" : field.formated}
          onChange={onChange}
          placeholder="$0"
          disabled={!!getFieldDefault(field, "disabled")}
        />
      </td>
      <td>
        {withMenu && (
          <Dropdown
            isOpen={showActions}
            toggle={() => setShowActions(!showActions)}
            className="d-none d-md-inline-block"
          >
            <DropdownToggle
              className="btn-match-input btn-none"
              title="Item actions"
            >
              <Icon icon="more_vert" />
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu--overscroll">
              {withRemove && (
                <DropdownItem
                  onClick={e => {
                    e.preventDefault()
                    onRemove(field)
                  }}
                >
                  <Icon icon="remove_circle_outline" className="text-danger" />{" "}
                  Remove item
                </DropdownItem>
              )}
              {isAdmin && !field.custom && (
                <DropdownItem
                  onClick={e => {
                    e.preventDefault()
                    field.saveToTemplate()
                  }}
                >
                  <Icon icon="save_alt" /> Save to Template
                </DropdownItem>
              )}
            </DropdownMenu>
          </Dropdown>
        )}
      </td>
    </tr>
  )
}

FieldRow.propTypes = {
  onChange: PropTypes.func.isRequired,
  onChangeName: PropTypes.func,
  onRemove: PropTypes.func,
  field: PropTypes.shape({
    id: PropTypes.string.isRequired,
    formated: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired
  })
}

export default FieldRow
