import { types, getSnapshot } from "mobx-state-tree"
import { omit } from "lodash/object"
import { TYPE_TEMPLATE } from "../constants"
import Document from "./Document"
import { sharedDB } from "../utils/db"
import ItemList from "./ItemList"

export async function createFromProject(project) {
  const template = Template.create({
    ...omit(getSnapshot(project), "propertyId", "rev", "id"),
    type: TYPE_TEMPLATE
  })
  project.app.addTemplate(template)
  await template.save()
  return template
}

const Template = types.compose(
  "Template",
  Document,
  ItemList,
  types
    .model({
      type: TYPE_TEMPLATE
    })
    .views(self => ({
      get db() {
        // because templates write to the shared db, not the private db
        return sharedDB
      },
      get searchableItems() {
        return self.allItems
      }
    }))
    .actions(self => ({
      validate() {
        self.clearValidationFailures()
        if (!self.name) {
          self.addValidationFailure("Please give your template a name")
        }
      }
    }))
)

export default Template
