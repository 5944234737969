import React from "react"
import PropTypes from "prop-types"
import { observer, PropTypes as MobxTypes, inject } from "mobx-react"
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap"
import Link from "../Link/index"
// import ProjectForm from "../ProjectForm"
import { propertyPage, projectPage } from "../../routes"
import { ReactComponent as Plus } from "../../images/white-plus.inline.svg"
import Button from "../Button"

const onSave = async (property, template) => {
  const project = property.createProjectFromTemplate(template)
  project.validate()
  if (project.isValid) {
    await project.save()
    projectPage.route(project.app.view, { id: project.id, suggestions: true })
  } else {
    project.validationFailures.map(({ message }) =>
      project.app.notify(message, { level: "warning" })
    )
  }
}

const AddProjectPage = ({ property, onSaved, viewer }) => (
  <span>
    <div className="back-button-top-container">
      <Link to={propertyPage.url({ id: property.id })}>
        <Button
          swatch
          color="secondary"
          className="back-button-top back-button-top--float"
          size="sm"
        >
          Back
        </Button>
      </Link>
    </div>
    <h1 className="swatch-title swatch-title--main">
      <small>Choose Budget</small>
      Category
    </h1>
    <Row className="row-space-items">
      {property.unusedTemplates.map(template => (
        <Col xs="6" sm="4" md="3" xl="2" key={template.id}>
          <span
            className="as-link"
            onClick={() => {
              onSave(property, template)
            }}
          >
            <Card className="project card-raised h-100">
              {/* 
                1. TODO Tim i'm just reusing code we have on property, but maybe for templates the image will come from elsewhere? either way, we will still have a default image 
                2. TODO Toivo looks like this should be a component then ? too much to type here
              */}
              {!template.imageSrc && (
                <div className="project-preview-image project-preview-image-default" />
              )}
              {template.imageSrc && (
                <div
                  className="project-preview-image"
                  style={{ backgroundImage: `url(${template.imageSrc})` }}
                />
              )}

              <CardBody className="project-preview-details p-3">
                <CardTitle className="mb-0">
                  <span className="as-link">{template.name}</span>
                </CardTitle>
              </CardBody>
            </Card>
          </span>
        </Col>
      ))}

      <Col
        xs="6"
        sm="4"
        md="3"
        xl="2"
        className="align-items-center d-flex justify-content-center"
      >
        <Button
          size="md"
          swatch="xl"
          color="primary"
          onClick={() => {
            const project = property.addProject()
            projectPage.route(viewer, { id: project.id, suggestions: true })
          }}
        >
          <Plus className="btn-swatch__icon btn-swatch__icon--larger" />
          <br />
          <span>
            Custom
            <br />
            Category
          </span>
        </Button>
      </Col>
    </Row>
  </span>
)

AddProjectPage.propTypes = {
  property: MobxTypes.observableObject.isRequired,
  onSaved: PropTypes.func
}

export default inject("viewer")(observer(AddProjectPage))
