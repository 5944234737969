import React, { Component } from "react"
import * as Sentry from "@sentry/browser"

// Sentry.init({
//  dsn: "https://cac3e71ba1d54f66982311b0d6b6c620@sentry.io/1311939"
// });
// should have been called before using it here
// ideally before even rendering your react app

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { error: null }
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error })
    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key])
      })
      Sentry.captureException(error)
    })
  }

  render() {
    if (this.state.error) {
      //render fallback UI
      // eslint-disable-next-line
      return <a onClick={() => Sentry.showReportDialog()}>Report feedback</a>
    } else {
      //when there's not an error, render children untouched
      return this.props.children
    }
  }
}
