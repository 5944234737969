import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { Input, FormGroup, Label, FormFeedback, FormText } from "reactstrap"

import "./inputSet.scss"

const InputSet = ({
  className,
  styleName,
  innerRef,
  onChange,
  label,
  id,
  name,
  feedback,
  help,
  valid,
  invalid,
  ...props
}) => {
  const htmlId = id || name

  return (
    <FormGroup>
      {label && <Label htmlFor={htmlId}>{label}</Label>}
      <Input
        innerRef={innerRef}
        id={htmlId}
        className={classNames(className)}
        onChange={e => {
          if (onChange) onChange(e.target.value)
        }}
        {...props}
        valid={valid}
        invalid={invalid}
      />
      {feedback && <FormFeedback valid={!invalid}>{feedback}</FormFeedback>}
      {help && <FormText>{help}</FormText>}
    </FormGroup>
  )
}

InputSet.propTypes = {
  className: PropTypes.string,
  styleName: PropTypes.string,
  onChange: PropTypes.func,
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  feedback: PropTypes.string,
  help: PropTypes.string,
  valid: PropTypes.bool,
  invalid: PropTypes.bool
}

export default InputSet
