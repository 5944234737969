import React from "react"
import PropTypes from "prop-types"
import { PropTypes as MobxTypes } from "mobx-react"
import PropertyForm from "../PropertyForm/index"

const PropertyEdit = ({ property, onSaved, onDelete }) => (
  <PropertyForm property={property} onSaved={onSaved} onDelete={onDelete} />
)

PropertyEdit.propTypes = {
  property: MobxTypes.observableObject,
  onSaved: PropTypes.func,
  onDelete: PropTypes.func
}

export default PropertyEdit
