import React from "react"
import PropTypes from "prop-types"
import { debounce } from "lodash/function"
import { observer, PropTypes as MobxTypes } from "mobx-react"
import ListItems from "../ListItems/index"
import { projectPage } from "../../routes/index"
import Link from "../Link/index"

const deleteItem = project => item => {
  if (window.confirm("Are you sure you want to remove this item?")) {
    item.toggleSuggested()
    project.save()
  }
}
const addItem = project => {
  project.addItem()
  saveProject(project)
}
const saveProject = debounce(project => {
  project.save()
}, 500)

const filterItems = (items, filter) => {
  return filter ? items.filter(filter) : items
}

const Title = ({ project }) => (
  <h5>
    <span className="mr-2">{project.name}</span>{" "}
    <strong className="tiny allcaps d-inline-block">
      <Link to={projectPage.url({ id: project.id })}>View / Edit</Link>
    </strong>
  </h5>
)

const ListProjectsDetail = ({
  projects,
  filter,
  showSuggested,
  showAddNew,
  searching
}) => {
  let projectsWithResults = 0

  return projects.map((project, index) => {
    const activeItems = filterItems(project.activeItems, filter)
    const suggestedItems = showSuggested
      ? filterItems(project.availableSuggestions, filter)
      : []
    const onAdd = () => addItem(project)
    const onChange = () => saveProject(project)
    const onDelete = deleteItem(project)
    const renderTitle = () => <Title project={project} />
    const hasItems = !!(activeItems.length || suggestedItems.length)

    projectsWithResults += hasItems ? 1 : 0

    return (
      <span key={project.id}>
        {hasItems && (
          <div className="mb-4">
            <ListItems
              displayTitle
              renderTitle={renderTitle}
              project={project}
              items={activeItems}
              onDelete={onDelete}
              onAdd={showAddNew ? onAdd : undefined}
              onChange={onChange}
              suggestedItems={suggestedItems}
              onAddSuggestion={project.addSuggestion}
              onSort={field => project.setSort(field)}
            />
          </div>
        )}
        {searching &&
          projects.length === index + 1 &&
          projectsWithResults === 0 &&
          "There are no budget items matching your search in any of your budget categories"}
      </span>
    )
  })
}

ListProjectsDetail.propTypes = {
  projects: MobxTypes.arrayOrObservableArrayOf(MobxTypes.observableObject)
    .isRequired,
  filter: PropTypes.func,
  showSuggested: PropTypes.bool,
  showAddNew: PropTypes.bool
}

export default observer(ListProjectsDetail)
