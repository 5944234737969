import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import "./icon.scss"

const Icon = ({ className, icon, size, ...props }) => {
  return (
    <span
      className={classNames(className, "icon material-icons", {
        [`icon--size-${size}`]: size
      })}
      {...props}
    >
      {icon}
    </span>
  )
}

Icon.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string
}

export default Icon
