import React from "react"
import { observer, inject } from "mobx-react"
import { Container } from "reactstrap"
import ListProperties from "../ListProperties"

const PropertiesPage = ({ renoTracker }) => {
  const completedProperties = renoTracker.completedProperties
  return (
    <Container className="container-fluid-max" fluid>
      <div className="text-center">
        <h1 className="swatch-title swatch-title--main">My Properties</h1>
      </div>

      <ListProperties
        renoTracker={renoTracker}
        properties={renoTracker.activeProperties}
        showAdd
      />

      {completedProperties.length > 0 && (
        <div className="mt-5">
          <div className="text-center">
            <h3 className="swatch-title">Completed</h3>
          </div>
          <div className="mt-4">
            <ListProperties
              renoTracker={renoTracker}
              properties={completedProperties}
            />
          </div>
        </div>
      )}
    </Container>
  )
}

export default inject("renoTracker")(observer(PropertiesPage))
