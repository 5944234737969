import React from "react"
import { PropTypes, observer } from "mobx-react"
import { Row, Col } from "reactstrap"

const Field = ({ label, value, className }) => (
  <Col xs="6" sm="auto" className={className}>
    {/* <h3 className="swatch-title swatch-title--pale"> */}
    <h3 className="swatch-title swatch-title--pale">
      {value}
      <small className="tiny">{label}</small>
    </h3>
  </Col>
)

const Overview = ({ model, ...props }) => (
  <Row {...props}>
    {/* <Field label="Total Budget" value={model.budgetFormatted} /> */}
    <Field
      label="Spent"
      value={model.spentFormatted}
      className="text-center pr-1 pr-sm-2"
    />
    <Field
      label="Left"
      value={model.availableFormatted}
      className="text-center pl-1 pl-sm-2"
    />
    {/* <Field label="% Available" value={model.percentAvailableFormatted} /> */}
  </Row>
)

Overview.propTypes = {
  model: PropTypes.observableObject.isRequired
}

export default observer(Overview)
