import { types, getParent, getSnapshot, addDisposer } from "mobx-state-tree"
import { generate } from "../utils/uuid"
import OverviewSum from "./OverviewSum"
import Document from "./Document"
import { TYPE_PROJECT, STATUS_ACTIVE } from "../constants"
import Template from "./Template"
import ItemList from "./ItemList"
import WithStatus from "./WithStatus"
import WithTime from "./WithTime"
import { reaction } from "mobx"

const TemplateByReference = types.maybeNull(
  types.reference(Template, {
    get(identifier, parent) {
      return parent.app.getOrLoadTemplate(identifier)
    },
    set(template) {
      return template.id
    }
  })
)

const Project = types.compose(
  "Project",
  OverviewSum,
  Document,
  ItemList,
  WithStatus,
  WithTime,
  types
    .model({
      type: TYPE_PROJECT,
      template: TemplateByReference,
      images: types.optional(types.array(types.array(types.string)), [
        ["before", "after"]
      ])
    })
    .views(self => {
      const superGetCouchFields = self.getCouchFields

      return {
        get property() {
          return getParent(self, 2)
        },
        getCouchFields() {
          return {
            ...superGetCouchFields(),
            propertyId: self.property ? self.property.id : null,
            activeProject: null
          }
        },
        get templateItems() {
          return self.template ? self.template.allItems : []
        },
        get availableSuggestions() {
          const activeNames = self.property.activeItemNames
          return self.templateItems.filter(i => !activeNames.includes(i.name))
        },
        get searchableItems() {
          return self.activeItems.concat(self.availableSuggestions)
        },
        get cloneData() {
          const data = {
            ...getSnapshot(self),
            rev: undefined,
            id: generate(),
            attachments: {},
            attachmentUrls: {},
            items: self.activeItems.reduce((res, item) => {
              const id = generate()
              return {
                ...res,
                [id]: {
                  ...getSnapshot(item),
                  spent: 0,
                  id
                }
              }
            }, {})
          }
          return data
        },
        get hasImages() {
          const images = self.images.find(
            ([before, after]) =>
              self.getImageSrc(before) !== null ||
              self.getImageSrc(after) !== null
          )

          return !!images
        }
      }
    })
    .actions(self => {
      return {
        setName(value) {
          self.name = value
        },
        setTemplate(value) {
          self.template = value
        },
        validate() {
          self.clearValidationFailures()
          if (!self.name) {
            self.addValidationFailure("Please give your project a name")
          }
        },
        addSuggestion(suggestion) {
          const suggestionValues = getSnapshot(suggestion)
          const item = {
            ...suggestionValues,
            suggestedBudget: suggestionValues.budget,
            status: STATUS_ACTIVE
          }
          self.addItem(item)
        },
        afterCreate() {
          const savePromptDisposer = reaction(
            () =>
              [
                // If any of these fields change, then we need to save
                self.totalBudget,
                self.name,
                self.notes,
                self.scopeOfWork
              ].concat(self.activeItems.map(({ name }) => name)),
            changes => {
              self.setSaved(false)
            }
          )
          addDisposer(self, savePromptDisposer)
        },
        addNewImage() {
          const count = self.images.length
          self.images = [...self.images, [`before-${count}`, `after-${count}`]]
        },
        deleteImage(id) {
          self.deleteAttachment(id)
        }
      }
    })
)

export default Project
