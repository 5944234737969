import db from "./db"

function saveDoc(doc) {
  return db.put(doc).catch(err => {
    if (err.name !== "conflict") {
      throw err
    }
    // ignore if doc already exists
  })
}

// without it we can't compile, its replaced in the couchdb
// when the reducer is actually done

export async function propertySpent() {
  const name = "propertySpent"
  const doc = {
    _id: `_design/${name}`,
    views: {
      [name]: {
        map: `function(doc) {
          if (
            doc.type === "project" &&
            doc.propertyId &&
            doc.status === "active"
          ) {
            var spent = Object.values(doc.items).reduce(function(total, item) {
              return !item.status || item.status === "active"
                ? total + item.spent
                : total
            }, 0)
            emit([doc.propertyId, doc._id], spent)
          }
        }`, // I had .toString here but minification was breaking things
        reduce: "_sum"
      }
    }
  }

  await saveDoc(doc)
  return name
}

export async function propertyBudget() {
  const name = "propertyBudget"
  const doc = {
    _id: `_design/${name}`,
    views: {
      [name]: {
        map: `function(doc) {
          if (
            doc.type === "project" &&
            doc.propertyId &&
            doc.status === "active"
          ) {
            var budget = Object.values(doc.items).reduce(function(total, item) {
              const qty = item.quantity || 1
              const budget = item.budget || 0
              return total + budget * qty
            }, 0)
            emit([doc.propertyId, doc._id], budget)
          }
        }`, // I had .toString here but minification was breaking things
        reduce: "_sum"
      }
    }
  }
  await saveDoc(doc)
  return name
}
