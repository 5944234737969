import { init as SentryInit } from "@sentry/browser"
import { Provider } from "mobx-react"
import { getSnapshot, Instance } from "mobx-state-tree"
import React from "react"
import ReactDOM from "react-dom"
import { StripeProvider } from "react-stripe-elements"
import "./app.scss"
import App from "./components/App"
import ErrorBoundary from "./components/ErrorBoundy"
import RenoTracker from "./models/RenoTracker"
import * as serviceWorker from "./serviceWorker"
import { RootStoreProvider } from "./stores"
import config from "./utils/config"
import privateDB, { remoteDB, remotePrivate } from "./utils/db"
import { init as initRouter } from "./utils/router"

if (!config.isDev) {
  SentryInit({
    dsn: config.sentryDSN,
    // release: "my-project-name@2.3.12" TODO
  })
}

let renoTracker = RenoTracker.create()

function renderApp() {
  console.log("RENDERAPP")
  ReactDOM.render(
    <StripeProvider apiKey={config.stripePublicKey}>
      <Provider renoTracker={renoTracker} viewer={renoTracker.view}>
        <RootStoreProvider>
          <ErrorBoundary>
            <App renoTracker={renoTracker} />
          </ErrorBoundary>
        </RootStoreProvider>
      </Provider>
    </StripeProvider>,
    document.getElementById("root")
  )
}

renderApp()

initRouter(renoTracker.view, window.location)

declare global {
  interface Window {
    renoTracker: Instance<typeof RenoTracker>
    db: PouchDB.Database
    remotePrivate: PouchDB.Database
    remoteDB: PouchDB.Database
  }
}

if (config.isDev) {
  window.renoTracker = renoTracker
  window.db = privateDB
  window.remotePrivate = remotePrivate
  window.remoteDB = remoteDB

  if (module.hot) {
    module.hot.accept(["./components/App"], () => {
      // Component definition changed, re-render app
      renderApp()
    })

    module.hot.accept(["./models/RenoTracker"], () => {
      const snapShot = getSnapshot(renoTracker)
      // Viewer model definition changed, recreate a new one from old state
      renoTracker = RenoTracker.create(snapShot)
      renderApp()
    })
  }
}

serviceWorker.register()
