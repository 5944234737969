import React, { Component } from "react"
import PropTypes from "prop-types"
import { Container, Row, Col } from "reactstrap"
import ReactLoading from "react-loading"

class Loading extends Component {
  static propTypes = {
    text: PropTypes.string,
    loaderColor: PropTypes.string,
    loaderType: PropTypes.string,
    loaderSize: PropTypes.string
  }

  static defaultProps = {
    text: "Loading...",
    loaderColor: "#35b8e2",
    loaderType: "cylon",
    loaderSize: "100px"
  }

  state = { show: false }

  componentDidMount() {
    this.timeout = setTimeout(() => {
      this.setState({ show: true })
    }, 1000)
  }

  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
  }

  render() {
    const { show } = this.state
    const { text, loaderColor, loaderType, loaderSize } = this.props

    return show ? (
      <Container fluid={true} className="h-100">
        <Row className="h-100">
          <Col
            xs="12"
            className="d-flex align-items-center justify-content-center"
          >
            <div className="text-center">
              <ReactLoading
                type={loaderType}
                color={loaderColor}
                height={loaderSize}
                width={loaderSize}
              />
              <p className="mt-1">{text}</p>
            </div>
          </Col>
        </Row>
      </Container>
    ) : null
  }
}

export default Loading
