import React, { Component, Fragment } from "react"
import { observer, inject } from "mobx-react"
import {
  Alert,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Form,
  FormGroup,
  Media,
  Nav,
  NavItem,
  Row
} from "reactstrap"
import classnames from "classnames"
import Icon from "../Icon"
import Button from "../Button"
import Loading from "../Loading"
import { ReactComponent as Logo } from "../../images/my-properties-square-white.inline.svg"

import "./login.scss"
import { sendLoginLink } from "../../utils/auth"
import Link from "../Link"
import { register } from "../../routes"

class LoginPage extends Component {
  state = {
    email: "",
    error: null,
    loading: false,
    sent: false,
    showInfo: false
  }

  componentDidMount() {
    document.body.classList.toggle("login-page")
  }
  componentWillUnmount() {
    document.body.classList.remove("login-page")
  }

  emailLink = async e => {
    e.preventDefault()
    const { email } = this.state

    this.setState({ error: null, loading: true })

    try {
      const res = await sendLoginLink(email)
      this.setState({ sent: res.sent, showInfo: false })
    } catch (e) {
      console.error(e)
      this.setState({ error: e })
    } finally {
      this.setState({ loading: false })
    }
  }

  toggleInfo = () => {
    this.setState({ showInfo: !this.state.showInfo })
  }

  onBackClick = () => {
    this.setState({ sent: false, email: "", error: null })
  }

  render() {
    const { renoTracker, message } = this.props
    const { error, loading, sent } = this.state

    return renoTracker.isLoading ? (
      <Loading />
    ) : (
      <Container fluid={true}>
        <div className="text-center mt-4">
          <h1 className="swatch-title swatch-title--main">
            <span>
              <span className="mr-2 mr-md-3 d-inline-block align-middle">
                <Logo className="login__logo" />
              </span>
              <span className="d-inline-block align-middle text-left">
                <small>
                  Welcome to
                  <br />
                </small>
                RenoTracker
              </span>
            </span>
          </h1>
        </div>
        <Row className="h-100">
          <Col
            xs="12"
            className="d-flex align-items-center justify-content-center flex-column"
          >
            <Card
              className={classnames("bg-transparent mw-20 w-100", {
                "mw-20": !sent,
                "mw-30": sent
              })}
            >
              <CardBody>
                <Form onSubmit={this.emailLink}>
                  {!loading && !sent && (
                    <FormGroup>
                      <Input
                        type="email"
                        className="form-control-lg"
                        placeholder="Enter your email to login"
                        autoComplete="email"
                        onChange={({ target: { value: email } }) =>
                          this.setState({ email })
                        }
                      />
                    </FormGroup>
                  )}
                  {error && (
                    <Alert color="warning">
                      {error.reason
                        ? error.reason.replace("Name", "Email")
                        : `Login Failed`}
                    </Alert>
                  )}
                  {message && <Alert color="warning">{message}</Alert>}

                  {sent && (
                    <span>
                      <Card className="text-center card-raised card-highlight mb-2">
                        <CardBody>
                          <h4>Check your email now!</h4>
                          <p>
                            Go to your email inbox and click on the link we just
                            sent you to login to RenoTracker.
                          </p>
                          <p className="text-center mb-0">
                            <small>
                              For your safety and convenience we email you a new
                              link each time you log in.
                            </small>
                          </p>
                        </CardBody>
                      </Card>
                    </span>
                  )}

                  {loading && <Loading text="Hang tight!" />}

                  {!loading && (
                    <Nav className="justify-content-center">
                      <NavItem>
                        {!sent && (
                          <Button
                            swatch
                            color="primary"
                            type="submit"
                            size="lg"
                          >
                            {/* Email<br/>me */}
                            Next
                          </Button>
                        )}
                        {sent && (
                          <Button
                            swatch
                            color="secondary"
                            type="submit"
                            size="lg"
                            onClick={this.onBackClick}
                          >
                            Try
                            <br />
                            again
                          </Button>
                        )}
                      </NavItem>
                      {/* <NavItem className="ml-auto">
                        <Link to={register.url()}>Register</Link>
                      </NavItem> */}
                    </Nav>
                  )}
                </Form>
              </CardBody>
            </Card>

            {!loading && (
              <Fragment>
                {!sent && (
                  <div style={{ marginBottom: "30px" }}>
                    <Link to={register.url()}>Register</Link>
                  </div>
                )}
                {!this.state.showInfo && (
                  <Button onClick={this.toggleInfo} size="sm" className="mt-2">
                    <Icon icon="help" size="sm" /> Need help logging in?
                  </Button>
                )}
                {this.state.showInfo && (
                  <Card className="card-raised mt-3 mw-50">
                    <CardBody>
                      <Media className="mb-2">
                        <Media left className="mr-2">
                          <Icon icon="info" size="lg" />
                        </Media>
                        <Media body>
                          <h3>How to login</h3>
                          <h6>
                            We use an email based login system. Here's how it
                            works:
                          </h6>
                        </Media>
                      </Media>
                      <ol>
                        <li>
                          Enter the email address you registered with, and hit
                          'email me'
                        </li>
                        <li>
                          Log in to your email, and find the message we just
                          sent you. Check your spam folder if you can't see it
                        </li>
                        <li>
                          Inside the email will be a link- click that to return
                          to RenoTracker, where you will now be logged in!
                        </li>
                        <li>
                          Your browser should keep you logged in even if you
                          close the tab, so you shouldn't need to do this often,
                          unless you click the 'logout' button from in the app
                        </li>
                      </ol>
                    </CardBody>
                  </Card>
                )}
              </Fragment>
            )}
          </Col>
        </Row>
      </Container>
    )
  }
}

export default inject("renoTracker")(observer(LoginPage))
