import { types } from "mobx-state-tree"
import OverviewStats from "./OverviewStats"

const OverviewSum = types.compose(
  OverviewStats,
  types
    .model()
    .views(self => ({
      get totalBudget() {
        return self.sumField("totalBudget")
      },
      get spent() {
        return self.sumField("spent")
      },
      get available() {
        return self.sumField("available")
      }
    }))
    .actions(self => ({
      sumField(field) {
        throw new Error("Not implemented")
      }
    }))
)

export default OverviewSum
