import React from "react"
import { Col, Row, FormGroup } from "reactstrap"
import { ReactComponent as Plus } from "../../../images/white-plus.inline.svg"
import ImageField from "../../ImageField"
import { observer } from "mobx-react"
import Button from "../../Button"
import Icon from "../../Icon"

const ImageSelector = ({ imageSize, project, onDrop, onDelete, onAdd }) => {
  return project.images.map(([before, after]) => {
    const beforeImage = project.getImageSrc(before)
    const afterImage = project.getImageSrc(after)

    return (
      <div key={before || after}>
        <Row>
          <Col sm="6">
            <FormGroup>
              <label>Before Image</label>
              {beforeImage && (
                <Button
                  icon
                  onClick={e => onDelete(e, before)}
                  title="Delete this image"
                  className="text-danger"
                >
                  <Icon icon="delete" /> Delete Image
                </Button>
              )}

              <ImageField
                resize={imageSize}
                fileUrl={project.getImageSrc(before)}
                onDropped={onDrop(before)}
              />
            </FormGroup>
          </Col>
          <Col sm="6">
            <FormGroup>
              <label>After Image</label>
              {afterImage && (
                <Button
                  icon
                  onClick={e => onDelete(e, after)}
                  title="Delete this image"
                  className="text-danger"
                >
                  <Icon icon="delete" /> Delete Image
                </Button>
              )}

              <ImageField
                resize={imageSize}
                fileUrl={project.getImageSrc(after)}
                onDropped={onDrop(after)}
              />
            </FormGroup>
          </Col>
          <Col sm="12 justify-content-center" style={{ marginBottom: "30px" }}>
            <Button color="primary" onClick={onAdd} className="mr-2">
              <Plus className="btn-swatch__icon" /> Add more
            </Button>
          </Col>
        </Row>
      </div>
    )
  })
}

export default observer(ImageSelector)
