import { Instance, flow, types } from "mobx-state-tree"
import { UserMetadata } from "../stores/entities"

interface UserVolatileTypes {
  error: undefined | string
}

export const Userv2 = types
  .model("Userv2", {
    name: types.maybe(types.string),
    roles: types.optional(types.array(types.string), []),
    metadata: types.optional(types.map(types.frozen<UserMetadata>()), {}),
  })
  .volatile<UserVolatileTypes>((_) => ({
    error: undefined,
  }))
  .views((self) => ({
    get avatar() {
      return self.metadata.get("avatar")
    },

    get hasAvatar() {
      return !!self.metadata.get("avatar")
    },

    get hasPlan() {
      return !!self.metadata.get("stripeSubscriptionId")
    },

    get isAuthenticated() {
      return !!self.name
    },

    get isAdmin() {
      return self.roles.includes("admin") || self.roles.includes("_admin")
    },
  }))
  .actions((self) => ({
    login: flow(function* authenticateUser(email: string, pass: string) {
      try {
        yield Promise.reject("Not implemented.")
        // const user = yield authDB.logIn(email, pass)
        // initDBs(user.name)
      } catch (e) {
        self.error = `Failed to sign in user ${email}`
      }
    }),
  }))

export interface UserType extends Instance<typeof Userv2> {}
