import React, { useState, useEffect, Fragment } from "react"
import PropTypes from "prop-types"
import classnames from "classnames"
import { format } from "date-fns"
import { getStripePlans, getSubscription } from "../../utils/stripe"
import Loading from "../Loading"
import "./StripePlans.scss"

function usePlans() {
  const [plans, setPlans] = useState()
  useEffect(() => {
    getStripePlans().then(setPlans)
  }, [])
  return [plans]
}

function useSubscription(id) {
  const [subscription, setSubscription] = useState()
  useEffect(() => {
    if (id) {
      getSubscription(id).then(setSubscription)
    }
  }, [id])
  return [subscription]
}

export default function StripePlans({
  renderAction,
  activeSubscriptionId,
  className
}) {
  const [products] = usePlans()
  const [subscription] = useSubscription(activeSubscriptionId)

  if (!products) {
    return <Loading />
  }

  return (
    <div
      className={classnames("container-fluid-max container-fluid", className)}
    >
      <div className="row-space-items row plans-list">
        {products.map(product => {
          return (
            <div key={product.id} className="col-12 col-md-6 col-lg-6">
              <div className="card-raised h-100 card">
                <div className="plan-select-hero">
                  <h2>{product.name}</h2>
                  <p>{product.metadata.description}</p>
                </div>
                <div className="plan-footer">
                  {product.plans.map(plan => {
                    const isActive =
                      subscription && subscription.plan.id === plan.id
                    const isFree = plan.amount === 0
                    const renewDate =
                      subscription &&
                      format(
                        new Date(subscription.current_period_end * 1000),
                        "PP"
                      )

                    return (
                      <Fragment key={plan.id}>
                        <div
                          className={classnames("plan-price", {
                            active: isActive
                          })}
                        >
                          {!isFree && (
                            <Fragment>
                              <div className="plan-price-subtitle">Price</div>
                              {new Intl.NumberFormat("en-NZ", {
                                style: "currency",
                                currency: plan.currency
                              }).format(plan.amount / 10)}
                            </Fragment>
                          )}
                          {isFree && (
                            <span className="plan-price-free">Free</span>
                          )}
                        </div>
                        {!isActive && renderAction(plan, product)}
                        {isActive && (
                          <div className="plan-current">
                            <h5>You Current Plan</h5>
                            <div className="plan-price-subtitle">
                              Renews {renewDate}
                            </div>
                          </div>
                        )}
                      </Fragment>
                    )
                  })}
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

StripePlans.propTypes = {
  activeSubscriptionId: PropTypes.string,
  className: PropTypes.string,
  renderAction: PropTypes.func.isRequired
}
