import React, { Component } from "react"
import { PropTypes } from "mobx-react"
import { FormGroup } from "reactstrap"
import Textarea from "../Textarea"

class Notes extends Component {
  static propTypes = {
    node: PropTypes.objectOrObservableObject.isRequired
  }

  setNotes = value => {
    const { node, onChange } = this.props
    node.setNotes(value)
    if (onChange) {
      onChange(value)
    }
  }

  setScopeOfWork = value => {
    const { node, onChange } = this.props
    node.setScopeOfWork(value)
    if (onChange) {
      onChange(value)
    }
  }

  render() {
    const { node } = this.props
    return (
      <div>
        <FormGroup>
          <Textarea
            autosize
            hasMinHeight
            label="Notes"
            defaultValue={node.notes}
            node={node}
            onChange={this.setNotes}
          />
        </FormGroup>
        <FormGroup>
          <Textarea
            autosize
            hasMinHeight
            label="Scope of work"
            defaultValue={node.scopeOfWork}
            node={node}
            onChange={this.setScopeOfWork}
          />
        </FormGroup>
      </div>
    )
  }
}

export default Notes
