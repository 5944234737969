import React from "react"
import classnames from "classnames"

import "./avatar.scss"

import { ReactComponent as BlackwithOne } from "./images/BlackwithOne.inline.svg"
import { ReactComponent as Brainy } from "./images/Brainy.inline.svg"
import { ReactComponent as CHAT } from "./images/CHAT.inline.svg"
import { ReactComponent as Corny } from "./images/Corny.inline.svg"
import { ReactComponent as CurlyMagee } from "./images/CurlyMagee.inline.svg"
import { ReactComponent as GetOnYa } from "./images/GetOnYa.inline.svg"
import { ReactComponent as GlassesPerson } from "./images/GlassesPerson.inline.svg"
import { ReactComponent as Hammerhead } from "./images/Hammerhead.inline.svg"
import { ReactComponent as Plain } from "./images/Plain.inline.svg"
import { ReactComponent as Sparkles } from "./images/Sparkles.inline.svg"
import { ReactComponent as Sweetlittledoggo } from "./images/Sweetlittledoggo.inline.svg"
import { ReactComponent as Thumbs } from "./images/Thumbs.inline.svg"
import { ReactComponent as Tree } from "./images/Tree.inline.svg"
import { ReactComponent as Whilemyguitargentlyweeps } from "./images/Whilemyguitargentlyweeps.inline.svg"

export const avatars = {
  BlackwithOne,
  Brainy,
  CHAT,
  Corny,
  CurlyMagee,
  GetOnYa,
  GlassesPerson,
  Hammerhead,
  Plain,
  Sparkles,
  Sweetlittledoggo,
  Thumbs,
  Tree,
  Whilemyguitargentlyweeps
}

export const ALL_AVATARS = Object.keys(avatars)

const Avatar = ({ id, size, className }) => {
  const AvatarSVG = avatars[id] || Thumbs
  return (
    <div
      className={classnames(
        className,
        "avatar",
        { "avatar-sm": size === "small" },
        { "avatar-lg": size === "large" }
      )}
    >
      <AvatarSVG />
    </div>
  )
}

export default Avatar
