import { types, getRoot } from "mobx-state-tree"
import { startCase } from "lodash/string"
import { format } from "../utils/price"
import { idType } from "../utils/model"
export const ACQUISITION = "acquisition"
export const SALE_PROCEEDS = "sale_proceeds"
export const FINANCIAL = "financial"
export const OTHER = "other"

const PriceField = types
  .model("PriceField", {
    id: idType(),
    value: 0,
    name: "",
    type: types.enumeration("Type", [
      ACQUISITION,
      SALE_PROCEEDS,
      FINANCIAL,
      OTHER
    ]),
    removable: true,
    custom: false,
    fromTemplate: false
  })
  .views(self => ({
    get label() {
      return self.name || startCase(self.id)
    },
    get formated() {
      return format(self.value)
    },
    get app() {
      return getRoot(self)
    }
  }))
  .actions(self => ({
    saveToTemplate() {
      const template = self.app.getOrLoadPALTemplate()
      template.saveField(self)
    }
  }))

export default PriceField
