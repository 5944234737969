import React from "react"
import { observer, PropTypes as MobxTypes } from "mobx-react"
import PropTypes from "prop-types"
import { Button, Badge } from "reactstrap"

import { ReactComponent as Plus } from "../../images/white-plus.inline.svg"

const ListSuggestedItem = observer(({ item, inlineWithItems, onAdd }) => (
  <tr className="budget-item-row budget-item-row--suggested row-accentuated">
    <td
      colSpan={inlineWithItems ? "2" : undefined}
      className="budget-item-row__suggested-name"
    >
      {inlineWithItems && (
        <small className="mr-2">
          <Badge color="primary">SUGGESTION</Badge>
        </small>
      )}
      {item.name}
    </td>
    <td className="text-right">{item.availableFormatted}</td>
    <td className="text-right" colSpan={inlineWithItems ? "3" : undefined}>
      {onAdd && (
        <Button
          color="as-link"
          size="sm"
          className="nowrap"
          onClick={() => onAdd(item)}
          title="Add Item"
        >
          <Plus className="btn-swatch__icon" /> Add
          <span className="d-none d-sm-inline"> to budget</span>
        </Button>
      )}
    </td>
  </tr>
))

ListSuggestedItem.propTypes = {
  item: MobxTypes.observableObject.isRequired,
  onAdd: PropTypes.func,
  inlineWithItems: PropTypes.bool
}

export default ListSuggestedItem
