import React from "react"
import ReactCompareImage from "react-compare-image"

import "./ImageComparison.scss"

const ImageComparison = ({ leftImage, rightImage, ...props }) => (
  <ReactCompareImage leftImage={leftImage} rightImage={rightImage} {...props} />
)

export default ImageComparison
