import { types } from "mobx-state-tree"
import { formatDate } from "../utils/date"

const WithTime = types
  .model("WithTime", {
    createdAt: 0,
    updatedAt: 0
  })
  .views(self => ({
    get createdDate() {
      return formatDate(self.createdAt)
    },
    get updatedDate() {
      return formatDate(self.updatedAt)
    }
  }))
  .actions(self => {
    const superSave = self.save
    return {
      save() {
        const now = Date.now()
        if (self.neverSaved) {
          self.createdAt = now
        } else {
          self.updatedAt = now
        }
        return superSave ? superSave() : null
      }
    }
  })

export default WithTime
