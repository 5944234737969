import { observer, PropTypes as MobxTypes } from "mobx-react"
import PropTypes from "prop-types"
import React from "react"
import { Table, Label, Button } from "reactstrap"

import { ReactComponent as Plus } from "../../images/white-plus.inline.svg"
import ListSuggestedItem from "../ListSuggestedItem/index"
import ListItem from "../ListItem/index"

import "./ListItems.scss"

const ListItems = ({
  items,
  onDelete,
  onAdd,
  onChange,
  renderTitle,
  displayStatusToggle,
  onShowSuggestions,
  showingSuggestions,
  suggestedItems,
  onAddSuggestion,
  onSort
}) => {
  const doSort = field => {
    if (onSort) {
      onSort(field)
      onChange()
    }
  }

  return (
    <Table
      size="sm"
      className="list-items list-items--transformer table-borderless table-headed"
    >
      <thead className="list-items__head">
        <tr className="list-items__head-row">
          <td
            style={{ width: "50%" }}
            className="list-items__head-description"
            onClick={() => doSort("name")}
          >
            {renderTitle ? renderTitle() : <h5>Items</h5>}
          </td>
          <td className="text-center list-items__head-quantity">
            <Label title="Quantity">Qty.</Label>
          </td>
          <td
            className="text-right minw-5 list-items__head-budget"
            onClick={() => doSort("budget")}
          >
            <Label>Budget</Label>
          </td>
          <td
            className="text-right minw-5 list-items__head-spent"
            onClick={() => doSort("spent")}
          >
            <Label>Spent</Label>
          </td>
          <td
            className="text-right list-items__head-available"
            onClick={() => doSort("available")}
          >
            <Label>Left</Label>
          </td>
          <td className="text-right list-items__head-actions">
            {/* Actions */}
          </td>
        </tr>
      </thead>
      <tbody className="list-items__body">
        {items.length > 0 &&
          items.map(item => (
            <ListItem
              key={item.id}
              item={item}
              defaultItemExpanded={!item.name}
              onDelete={onDelete}
              onChange={onChange}
              displayStatusToggle={displayStatusToggle}
            />
          ))}
        {suggestedItems &&
          suggestedItems.length > 0 &&
          suggestedItems.map(item => (
            <ListSuggestedItem
              inlineWithItems
              key={item.id}
              item={item}
              onAdd={onAddSuggestion}
            />
          ))}
        {(!items || items.length === 0) &&
          (!suggestedItems || suggestedItems.length === 0) && (
            <tr>
              <td colSpan="6">
                <div className="p-3 small text-center">
                  You don't have any items in this budget yet- start adding some
                  using the button below.
                </div>
              </td>
            </tr>
          )}
        <tr className="budget-item-row budget-item-row--add">
          <td colSpan="6">
            {onAdd && (
              <Button
                // color={renderTitle ? "secondary" : "primary"}
                color="primary"
                onClick={onAdd}
                className="mr-2"
              >
                <Plus className="btn-swatch__icon" /> New Item
              </Button>
            )}
            {onShowSuggestions && (
              <Button
                color="secondary"
                onClick={onShowSuggestions}
                size="sm"
                className="italic px-1"
              >
                {showingSuggestions ? "Hide" : "Show"} suggestions
              </Button>
            )}
          </td>
        </tr>
      </tbody>
    </Table>
  )
}

ListItems.propTypes = {
  items: MobxTypes.arrayOrObservableArrayOf(MobxTypes.observableObject),
  suggestedItems: MobxTypes.arrayOrObservableArrayOf(
    MobxTypes.observableObject
  ),
  onDelete: PropTypes.func.isRequired,
  onAdd: PropTypes.func,
  onChange: PropTypes.func,
  onAddSuggestion: PropTypes.func,
  onShowSuggestions: PropTypes.func,
  showingSuggestions: PropTypes.bool,
  renderTitle: PropTypes.func,
  onSort: PropTypes.func
}

export default observer(ListItems)
