import React from "react"
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap"
import { observer } from "mobx-react"
import { projectPage } from "../../routes/index"
import Link from "../Link/index"
import Progress from "../Progress"
import Icon from "../Icon"

import "./ProjectCard.scss"
import { STATUS_COMPLETED } from "../../constants"

const ProjectCard = ({ project }) => {
  const progressValue = project.percentUsed
  // note we process the percent when passing it into progress bar, as we want 117% to display like 17% not 100% (and then we add red coloring to show it is 'over')
  const progressBarValue =
    progressValue > 100 ? progressValue - 100 : progressValue
  const progressBarText =
    project.status === STATUS_COMPLETED ? "" : project.percentUsedFormatted
  const progressBarIcon =
    project.status === STATUS_COMPLETED ? (
      <Icon icon="check" size="lg" />
    ) : (
      false
    )

  return (
    <Card className="project card-raised h-100">
      <Link to={projectPage.url({ id: project.id })}>
        {!project.imageSrc && (
          <div className="project-preview-image project-preview-image-default" />
        )}
        {project.imageSrc && (
          <div
            className="project-preview-image"
            style={{ backgroundImage: `url(${project.imageSrc})` }}
          />
        )}
      </Link>

      <CardBody className="project-preview-details p-3">
        <Progress
          className="project-progress"
          percentage={progressBarValue}
          text={progressBarText}
          icon={progressBarIcon}
          status={progressValue > 100 ? "danger" : ""}
          withBG
        />

        <CardTitle className="project-title mb-0">
          <Link to={projectPage.url({ id: project.id })}>{project.name}</Link>
        </CardTitle>
        <Row className="justify-content-center mt-2 mt-sm-3">
          <Col xs="auto" className="px-1 px-sm-2">
            <h5 className="mb-0 d-none d-sm-block">{project.spentFormatted}</h5>
            <h6 className="mb-0 d-sm-none">{project.spentFormatted}</h6>
            <p className="mb-0">
              <small>Spent</small>
            </p>
          </Col>
          <Col xs="auto" className="px-1 px-sm-2">
            <h5 className="mb-0 d-none d-sm-block">
              {project.availableFormatted}
            </h5>
            <h6 className="mb-0 d-sm-none">{project.availableFormatted}</h6>
            <p className="mb-0">
              <small>Left</small>
            </p>
          </Col>
        </Row>
        <Link
          className="d-none d-sm-block"
          to={projectPage.url({ id: project.id })}
        >
          View Details
        </Link>
      </CardBody>
    </Card>
  )
}

export default observer(ProjectCard)
