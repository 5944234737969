import { Component } from "react"
import PropTypes from "prop-types"

class AddressFinder extends Component {
  static propTypes = {
    apiKey: PropTypes.string.isRequired,
    onSelect: PropTypes.func.isRequired,
    render: PropTypes.func.isRequired,
    country: PropTypes.string,
    onRef: PropTypes.func,
    options: PropTypes.object
  }

  static defaultProps = {
    options: {},
    country: "NZ"
  }

  componentDidMount() {
    this.downloadAF(() => {
      this.withInputRef().then(el => {
        if (this.props.onRef) {
          this.props.onRef(el)
        }
        this.initAutocompleteWidget(el)
      })
    })
  }

  downloadAF = f => {
    var script = document.createElement("script")
    script.src = "https://api.addressfinder.io/assets/v3/widget.js"
    script.async = true
    script.onload = f
    document.body.appendChild(script)
  }

  initAutocompleteWidget = el => {
    const { options, onSelect, apiKey, country } = this.props
    const widget = new global.AddressFinder.Widget(el, apiKey, country, {
      address_params: {
        post_box: "0",
        delivered: "1",
        ...options
      }
    })
    widget.on("result:select", (fullAddress, metaData) => {
      const selected = new global.AddressFinder.NZSelectedAddress(
        fullAddress,
        metaData
      )
      onSelect(
        {
          address1: selected.address_line_1(),
          address2: selected.address_line_2(),
          suburb: selected.suburb(),
          city: selected.city(),
          postcode: selected.postcode(),
          lng: metaData.x,
          lat: metaData.y,
          fullAddress,
          id: metaData.dpid,
          pxid: metaData.pxid
        },
        { selected, metaData, fullAddress }
      )
    })
  }

  withInputRef = () => {
    return new Promise(resolve => {
      if (this.inputRef) {
        return resolve(this.inputRef)
      }
      setTimeout(() => resolve(this.withInputRef()), 10)
    })
  }

  setRef = inputEl => {
    this.inputRef = inputEl
  }

  render() {
    return this.props.render({ setRef: this.setRef })
  }
}

export default AddressFinder
