import { Instance, types } from "mobx-state-tree"
import { Userv2 as User } from "../models"

export const RootStore = types
  .model("RootStore", {
    user: types.optional(User, {}),
  })
  .actions((self) => ({
    createUser() {
      self.user = User.create()
    },
  }))

export interface RootStoreType extends Instance<typeof RootStore> {}
