import React, { Component } from "react"
import Dropzone from "react-dropzone"
import PropTypes from "prop-types"
import ImageTools from "../../utils/image"
import Icon from "../Icon"

import "./ImageField.scss"

class ImageField extends Component {
  static propTypes = {
    onDropped: PropTypes.func.isRequired,
    fileUrl: PropTypes.string,
    prompt: PropTypes.string,
    resize: PropTypes.shape({
      width: PropTypes.number.isRequired,
      height: PropTypes.number.isRequired
    })
  }

  static defaultProps = {
    prompt: "Drop an image here, or tap to select image to upload"
  }

  onDropImage = async files => {
    const { onDropped, resize } = this.props
    let file = files[0]
    if (file) {
      if (resize) {
        file = await ImageTools.resize(file, resize)
      }
      onDropped(file)
    }
  }

  render() {
    const {
      fileUrl,
      prompt,
      resize: { width, height }
    } = this.props

    const imageStyle = {
      // TODO Toivo, images aren't showing previews, I added this to try fix
      maxWidth: width,
      maxHeight: height,
      height: "auto",
      width: "auto"
    }
    return (
      <Dropzone
        className="form-control dropzone"
        accept="image/jpeg, image/png"
        onDrop={this.onDropImage}
      >
        {fileUrl && <img src={fileUrl} style={imageStyle} alt="Uploaded" />}
        {!fileUrl && (
          <div>
            <Icon icon="add_photo_alternate" size="lg" />
            <p>{prompt}</p>
          </div>
        )}
      </Dropzone>
    )
  }
}

export default ImageField
