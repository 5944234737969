export interface ClientConfig {
  addressFinderKey: string
  apiUri: string
  dbHost: string
  dbName: string
  isDev: boolean
  nodeEnv: string
  sentryDSN: string
  stripePublicKey: string
}

export const getConfig = (): ClientConfig => {
  const config: ClientConfig = {
    addressFinderKey: process.env.REACT_APP_ADDRESS_FINDER_KEY!,
    apiUri: process.env.REACT_APP_API_URI!,
    dbHost: process.env.REACT_APP_DB_HOST!,
    dbName: process.env.REACT_APP_DB_NAME!,
    isDev: process.env.NODE_ENV === "development",
    nodeEnv: process.env.NODE_ENV,
    sentryDSN: process.env.REACT_APP_SENTRY_DSN!,
    stripePublicKey: process.env.REACT_APP_STRIPE_PUBLIC_KEY!,
  }

  for (const [k, v] of Object.entries(config)) {
    if (v === undefined) {
      throw new Error(`${k} must be defined.`)
    }
  }

  return config
}

export default getConfig()
