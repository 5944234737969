import React from "react"
import { NavLink as RSNavLink } from "reactstrap"
import { route } from "../../utils/router"
import { inject, observer } from "mobx-react"

const NavLink = ({ onClick, children, viewer, ...props }) => {
  return (
    <RSNavLink
      {...props}
      onClick={e => {
        if (onClick) {
          onClick(e)
        } else if (props.href && !props.href.match(/^http/)) {
          e.preventDefault()
          route(viewer, props.href)
        }
      }}
    >
      {children}
    </RSNavLink>
  )
}

export default inject("viewer")(observer(NavLink))
