export const TYPE_PROPERTY = "property"
export const TYPE_PROFIT_AND_LOSS = "profit-and-loss"
export const TYPE_PROFIT_AND_LOSS_TEMPLATE = "profit-and-loss-template"
export const TYPE_USER = "user"
export const TYPE_PROJECT = "project"
export const TYPE_TEMPLATE = "template"
export const TYPE_ITEM = "item"
export const TYPE_RENOTRACKER = "renotracker"

export const STATUS_DRAFT = "draft"
export const STATUS_ACTIVE = "active"
export const STATUS_COMPLETED = "completed"
export const STATUS_DELETED = "deleted"
export const STATUS_ITEM_SUGGESTED = "optional"

export const IMG_DEFAULT_WIDTH = 1280
export const IMG_DEFAULT_HEIGHT = 720
