import React from "react"
import { observer } from "mobx-react"
import { Alert } from "reactstrap"

const Notifications = ({ renoTracker }) => {
  const { uniqueNotifications } = renoTracker
  return uniqueNotifications.length ? (
    <div className="notifications">
      {uniqueNotifications.map(({ message, level }, i) => (
        <Alert
          key={i}
          color={level}
          toggle={() => renoTracker.clearNotification(message)}
        >
          {message}
        </Alert>
      ))}
    </div>
  ) : null
}

export default observer(Notifications)
