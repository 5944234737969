import pathMatch from "path-match"
import { trimEnd } from "lodash/string"
import { difference } from "lodash/array"
import { urlParamify, mergeQSParams } from "../utils/request"
import { addFilter } from "../utils/hooks"

/**
 * This is the base functionality shared by all links
 * @param {object} params
 * @param {string} params.template - The template for this URL /foo/bar/:id
 * @param {function} params.handle - The function that should be called when we match this URL
 * @param {function} params.isActive - The function that determine if the MST Viewer state corresponds to this URL,
 * @param {function} params.argsFromState - This is used by Viewer.currentURL to convert the Viewers state into a URL. It populates template and adds anything else to query string
 * @param {function} params.argsForQueryString - Any args not found as :placeholders in template will be appended to the query string after passing through this function
 */
export default function route({
  template,
  handle,
  isActive,
  argsFromState,
  argsForQueryString,
  render
}) {
  if (typeof template === "undefined") {
    throw new Error("Required param template not provided.")
  }
  if (typeof handle === "undefined") {
    throw new Error(`Required param handle not provided to route: ${template}`)
  }
  if (typeof isActive === "undefined") {
    throw new Error(
      `Required param isActive not provided to route: ${template}`
    )
  }

  const pathMatcher = pathMatch()(template)

  const routeObject = {
    /**
     * Should return false if this path doesn't match our template
     * if it does match it should return the params found in the path
     *
     * @param {string} path - can include a query string param, qs params will also be parsed and returned if path matches
     */
    matchPath(path) {
      const [pathName, queryString] = path.split("?")
      // Need to exclude the query string before using pathMatcher or it doesn't work as expected
      const pathParams = pathMatcher(pathName)
      if (pathParams === false) return false
      return queryString ? mergeQSParams(pathParams, queryString) : pathParams
    },

    /**
     * return params applicable to this link from the Viewer state
     * This is used by Viewer.currentURL to convert the Viewers state into a URL. Args will populate template and add anything else as a query string
     *
     * @param {object} models/Viewer.js instance
     */
    argsFromState(viewer) {
      if (template.indexOf(":") > -1 && !argsFromState) {
        console.warn(
          "You have template parameter(s), but you don't have a corresponding argsFromState method to set these params from viewer state, things won't work correctly until you do",
          template
        )
      }
      return argsFromState ? argsFromState(viewer) : {}
    },

    /**
     * return true if this link is applicable to the current Viewer state
     * @param {object} models/Viewer.js instance
     */
    isActive(viewer) {
      return isActive ? isActive(viewer) : false
    },

    /**
     * Fills template with arguments
     * any extras are appended as a query string
     * @param {object} args
     */
    url(args = {}) {
      if (template.indexOf(":") > -1) {
        const argKeys = Object.keys(args)
        // used to record the template variables we have replaced
        const replaced = []

        // replace :{key} values in template with values in args object
        const path = trimEnd(
          template.replace(/(:([a-z0-9]+)\??)/gi, (_, key, value) => {
            if (value in args) {
              replaced.push(value)
              const v = args[value]
              if (v !== undefined) {
                return encodeURIComponent(v) // replace with arg value
              }
            }
            if (key.match(/\?$/)) return "" // this is an optional template i.e. tab? replace with empty string
            console.warn(`Missing ${key} in args for ${template}`)
            return key // return key as found so caller can see it needs to be replaced
          }),
          "/"
        )

        // These arguments where not found in the template
        const nonPathParams = difference(argKeys, replaced)
        const queryStringArgs = nonPathParams.reduce((qs, key) => {
          const value = args[key]
          if (value !== undefined) {
            return {
              ...qs,
              [key]: encodeURIComponent(value) // replace with arg value
            }
          }
          return qs
        }, {})

        if (Object.keys(queryStringArgs).length) {
          return path + "?" + urlParamify(queryStringArgs)
        }

        return path
      }
      return template
    },

    argsForQueryString(args) {
      return argsForQueryString ? argsForQueryString(args) : args
    },

    /**
     * Calls
     * @param {object} Viewer MST instance passed to handle function
     * @param {object} args passed to the resolved handle function
     */
    route(viewer, args) {
      return Promise.resolve(handle(viewer)(args))
    }
  }

  addFilter("routes", (routes = []) => routes.concat(routeObject))

  if (render) {
    renderRoute(routeObject, render)
  }

  return routeObject
}

export function renderRoute(route, renderFunction, priority = 10) {
  addFilter(
    "App.renderRoute",
    (component, viewer) => {
      return !component && route.isActive(viewer)
        ? renderFunction(viewer)
        : component
    },
    priority
  )
}
