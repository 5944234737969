import React, { Component } from "react"
import { observer } from "mobx-react"
import PropTypes from "prop-types"
import classnames from "classnames"
import { Card, CardBody, Nav, NavItem } from "reactstrap"
import { propertyPage, home } from "../../routes/index"
import PropertyDetail from "../PropertyDetail"
import PropertyEdit from "../PropertyEdit"
import TradeTracker from "../TradeTracker"
import Icon from "../Icon"
import Link from "../Link"
import Button from "../Button"
import BudgetTab from "./BudgetTab"
import GalleryTab from "./GalleryTab"

const TAB_BUDGET = "budget"
const TAB_TRADETRACKER = "tradetracker"
export const TAB_GALLERY = "gallery"

class Property extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tab: props.defaultTab
    }
  }

  static propTypes = {
    property: PropTypes.object,
    defaultTab: PropTypes.string,
    editing: PropTypes.bool,
    onSwitch: PropTypes.func,
    onStatusToggle: PropTypes.func,
    onDelete: PropTypes.func,
    onClone: PropTypes.func
  }

  static defaultProps = {
    defaultTab: TAB_BUDGET
  }

  openBudget = () => {
    this.setState({
      tab: TAB_BUDGET
    })
  }

  openGallery = () => {
    this.setState({
      tab: TAB_GALLERY
    })
  }

  openTradeTracker = () => {
    this.setState({
      tab: TAB_TRADETRACKER
    })
  }

  render() {
    const {
      property,
      editing,
      onSwitch,
      onStatusToggle,
      onDelete,
      onClone
    } = this.props
    const { tab } = this.state

    return (
      <div className="property">
        <div
          className={classnames("back-button-top-container", {
            "back-button-top-container--no-collapse": !editing
          })}
        >
          <Link
            to={editing ? propertyPage.url({ id: property.id }) : home.url()}
          >
            <Button
              swatch
              color="secondary"
              className={classnames("back-button-top")}
              size="sm"
            >
              Back
            </Button>
          </Link>
        </div>
        {editing && (
          <h1 className="swatch-title swatch-title--main swatch-title--main swatch-title--main-small">
            <small>Edit</small>
            Reno Details
          </h1>
        )}
        {!editing && (
          <Card className="card-raised mw-70 m-auto card">
            <PropertyDetail
              property={property}
              onStatusToggle={onStatusToggle}
              onSwitch={onSwitch}
              onClone={onClone}
              editing={editing}
            />
            <Nav tabs>
              <NavItem active={tab === TAB_BUDGET}>
                <span
                  className="btn as-link"
                  onClick={this.openBudget}
                  title="View the budget for this project"
                >
                  <Icon icon="track_changes" size="md" /> Budget
                </span>
              </NavItem>
              <NavItem active={tab === TAB_GALLERY}>
                <span
                  className="btn as-link"
                  onClick={this.openGallery}
                  title="View all the images of this project"
                >
                  <Icon icon="photo_library" size="md" /> Gallery
                </span>
              </NavItem>
              <NavItem active={tab === TAB_TRADETRACKER}>
                <span
                  className="btn as-link"
                  onClick={this.openTradeTracker}
                  title="View statistics for this project"
                >
                  <Icon icon="attach_money" size="md" /> Trade Tracker
                </span>
              </NavItem>
            </Nav>

            <CardBody>
              {tab === TAB_BUDGET && <BudgetTab property={property} />}
              {tab === TAB_GALLERY && <GalleryTab property={property} />}
              {tab === TAB_TRADETRACKER && (
                <TradeTracker profitAndLoss={property.profitAndLoss} />
              )}
            </CardBody>
          </Card>
        )}
        {editing && (
          <Card className="mw-30 m-auto card-raised">
            <CardBody>
              <PropertyEdit property={property} onDelete={onDelete} />
            </CardBody>
          </Card>
        )}
      </div>
    )
  }
}
export default observer(Property)
