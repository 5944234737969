import { inject, observer } from "mobx-react"
import classnames from "classnames"
import React, { Component } from "react"
import {
  Alert,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Nav,
  NavItem,
  Row
} from "reactstrap"
import InputSet from "../InputSet"
// import { home, plans, selectAvatar } from "../../routes/index"
import { selectAvatar } from "../../routes/index"
import Button from "../Button"
import Loading from "../Loading"

class RegisterPage extends Component {
  state = {
    fields: { fullName: "", email: "", phone: "" },
    fieldErrors: [],
    error: null,
    loading: false
  }

  fieldValidation = {
    email: v => (v && v.indexOf("@")) || "Please enter a valid email address.",
    phone: v => (v && v.length > 5) || "Please enter a valid phone number",
    fullName: v =>
      (v && v.length > 4 && v.match(/^[a-z]+\s[a-z]+/i)) ||
      "Please enter your first and last name"
  }

  setFields = fields => {
    this.setState({
      fields: {
        ...this.state.fields,
        ...fields
      }
    })
  }

  signUp = async e => {
    e.preventDefault()
    const { renoTracker } = this.props
    const { fields } = this.state

    const errors = Object.entries(this.fieldValidation).reduce(
      (errors, [field, validator]) => {
        const res = validator(fields[field])
        if (typeof res === "string") {
          return errors.concat({ field: field, message: res })
        }
        return errors
      },
      []
    )
    this.setState({ fieldErrors: errors })

    if (errors.length) {
      return false
    }

    try {
      this.setState({ loading: true })
      await renoTracker.signUp(fields)
      // await plans.route(renoTracker.view)
      await selectAvatar.route(renoTracker.view)
      return true
    } catch (e) {
      if (e.status === 409) {
        this.setState({
          fieldErrors: this.state.fieldErrors.concat({
            field: "email",
            message:
              "This email address has already been registered. You should try logging in!"
          })
        })
      } else {
        console.log(e)
        this.setState({ error: e })
      }
      this.setState({ loading: false })
    }
    return false
  }

  fieldError = field => {
    const error = this.state.fieldErrors.find(e => e.field === field)
    if (error) {
      return error.message
    }
  }

  render() {
    const { error, fields, loading } = this.state

    return (
      <Container fluid={true}>
        <div className="text-center mt-5">
          <h1 className="swatch-title swatch-title--main">Register</h1>
        </div>
        <Row className="h-100">
          <Col
            xs="12"
            className="d-flex align-items-center justify-content-center flex-column"
          >
            <Card className={classnames("card-raised w-100 mw-30")}>
              <CardBody>
                {loading && <Loading />}
                {!loading && (
                  <Form onSubmit={this.signUp}>
                    <InputSet
                      label="Full Name"
                      type="text"
                      value={fields.fullName}
                      onChange={fullName => this.setFields({ fullName })}
                      feedback={this.fieldError("fullName")}
                      invalid={!!this.fieldError("fullName")}
                    />
                    <InputSet
                      label="Email"
                      type="email"
                      value={fields.email}
                      onChange={email => this.setFields({ email })}
                      feedback={this.fieldError("email")}
                      invalid={!!this.fieldError("email")}
                    />
                    <InputSet
                      label="Phone Number"
                      type="tel"
                      value={fields.phone}
                      onChange={phone => this.setFields({ phone })}
                      feedback={this.fieldError("phone")}
                      invalid={!!this.fieldError("phone")}
                    />
                    <Nav className="justify-content-center">
                      <NavItem>
                        <Button type="submit" color="primary" size="lg" swatch>
                          Next
                        </Button>
                      </NavItem>
                    </Nav>
                    {error && (
                      <Alert color="warning">
                        {error.reason ||
                          "Something isn't working right, it's us not you."}
                      </Alert>
                    )}
                  </Form>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default inject("renoTracker")(observer(RegisterPage))
