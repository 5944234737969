import React from "react"
import { inject, observer } from "mobx-react"
import PropertiesNav from "../PropertiesNav"
import Notifications from "../Notifications"
import AppBar from "../AppBar"

import "./default.scss"

const DefaultLayout = ({ renoTracker, children }) => (
  <div className="app-layout">
    <AppBar />
    <div className="app-layout__main">
      {false && ( //renoTracker.hasProperties && (
        <div className="app-layout__side-bar">
          <PropertiesNav renoTracker={renoTracker} />
        </div>
      )}
      <div className="app-layout__view">
        <Notifications renoTracker={renoTracker} />

        <div className="app-layout__view-inner minh-100">{children}</div>
      </div>
    </div>
  </div>
)

export default inject("renoTracker")(observer(DefaultLayout))
