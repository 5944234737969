import React from "react"
import { observer, PropTypes as MobxTypes } from "mobx-react"
import { Container, Card, CardBody } from "reactstrap"
import PropertyForm from "../PropertyForm"
import Link from "../Link"
import Button from "../Button"
import { home } from "../../routes/index"

const AddProperty = ({ property }) => (
  <Container className="container-fluid-max" fluid>
    <div className="back-button-top-container">
      <Link to={home.url()}>
        <Button swatch color="secondary" className="back-button-top" size="sm">
          Back
        </Button>
      </Link>
    </div>
    <h1 className="swatch-title swatch-title--main">New Reno</h1>

    <Card className="mw-30 m-auto card-raised">
      <CardBody>
        <PropertyForm property={property} isEditingExisting={false} />
      </CardBody>
    </Card>
  </Container>
)

AddProperty.propTypes = {
  property: MobxTypes.observableObject.isRequired
}

export default observer(AddProperty)
