import React from "react"
import { observer, inject } from "mobx-react"
import classNames from "classnames"
import { Container } from "reactstrap"
import Avatar from "../Avatar"

import "./SelectAvatar.scss"
import { ALL_AVATARS } from "../Avatar/index"

const SelectAvatar = ({ user, viewer }) => (
  <Container className="container-fluid-max mt-4 mb-5" fluid>
    <div className="text-center">
      <h1 className="swatch-title swatch-title--main ">
        <small>
          Choose your
          <br />
        </small>
        Avatar
      </h1>
    </div>

    <div className="avatar-select-list">
      {ALL_AVATARS.map(a => (
        <div
          key={a}
          className={classNames("avatar-select", {
            "avatar-select--active": user.avatar === a
          })}
        >
          <label htmlFor={a}>
            <input
              id={a}
              type="radio"
              value={a}
              checked={user.avatar === a}
              onChange={async () => {
                user.setAvatar(a)
                await user.update()
                return viewer.openHomePage()
              }}
            />
            <Avatar id={a} />
          </label>
        </div>
      ))}
    </div>
    {/* <Nav className="justify-content-center">
      <NavItem>
        <Button swatch color="primary" size="lg">
          Next
        </Button>
      </NavItem>
    </Nav> */}
  </Container>
)

export default inject("viewer")(observer(SelectAvatar))
