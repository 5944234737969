import React from "react"
import PropTypes from "prop-types"
import { observer, inject } from "mobx-react"
import { route } from "../../utils/router"

const Link = ({
  to,
  onClick,
  children,
  disabled,
  replace,
  viewer,
  ...props
}) => {
  const href = to && typeof to === "string" ? to : undefined
  return (
    <a
      href={href}
      onClick={e => {
        if (!disabled) {
          if (onClick) {
            onClick(e)
          } else if (typeof to === "function") {
            to(e)
          } else if (href) {
            e.preventDefault()
            route(viewer, href)
          }
        }
      }}
      {...props}
    >
      {children}
    </a>
  )
}

Link.propTypes = {
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  replace: PropTypes.bool,
  className: PropTypes.string
}

Link.defaultProps = {
  disabled: false
}

export default inject("viewer")(observer(Link))
