import React from "react"
import classNames from "classnames"
import { Button as ReactstrapButton } from "reactstrap"

import "./button.scss"
import { ReactComponent as Swatch } from "../../images/square-paint-swatch.inline.svg"

const Button = ({ children, onClick, swatch, icon, className, ...props }) => (
  <ReactstrapButton
    className={classNames(className, {
      "btn-swatch": swatch,
      "btn-swatch-primary": swatch && props.color === "primary",
      "btn-swatch-secondary": swatch && props.color === "secondary",
      "btn-swatch-lg": swatch === "lg",
      "btn-swatch-xl": swatch === "xl",
      "btn-icon": icon
    })}
    onClick={onClick}
    {...props}
  >
    {swatch && <Swatch className="btn__swatch" />}
    {children}
  </ReactstrapButton>
)

export default Button
