import React from "react"
import PropTypes from "prop-types"
import { observer, PropTypes as MobxTypes } from "mobx-react"
import { Input, InputGroup, InputGroupAddon } from "reactstrap"
import { format } from "../../utils/price"
import { SALE_PROCEEDS } from "../../models/PriceField"
import FieldRow from "./FieldRow"
import AddCustomField from "./AddCustomField"
import { Label } from "reactstrap"

const SaleProceeds = observer(
  ({ profitAndLoss, onChange, onChangeName, onRemove }) => (
    <div>
      <label htmlFor="includeAgentFees">Include agent fees</label>
      <input
        id="includeAgentFees"
        type="checkbox"
        checked={profitAndLoss.includeAgentFees}
        onChange={({ target: { checked } }) =>
          profitAndLoss.setIncludeAgentFees(checked)
        }
      />
      <table className="list-items list-items--transformer table-borderless table-headed table table-sm">
        <thead className="list-items__head">
          <tr className="list-items__head-row">
            <td
              style={{ width: "70%" }}
              className="list-items__head-description"
            >
              <h5>Items</h5>
            </td>
            <td className="text-right minw-5 list-items__head-budget">
              <Label>Value</Label>
            </td>
            <td className="text-right list-items__head-actions">
              {/* Actions */}
            </td>
          </tr>
        </thead>
        <tbody>
          {profitAndLoss.saleProceedFields.map(field => (
            <FieldRow
              key={field.id}
              field={field}
              onChange={onChange}
              onRemove={field.removable ? onRemove : undefined}
            />
          ))}
          {profitAndLoss.customSaleProceedFields.map(field => (
            <FieldRow
              key={field.id}
              field={field}
              onChange={onChange}
              onChangeName={onChangeName}
              onRemove={onRemove}
            />
          ))}
          <tr>
            <td>
              <div>
                Agent Fees
                <div style={{ width: "20%", display: "inline-block" }}>
                  <InputGroup>
                    <Input
                      type="number"
                      className="form-control"
                      value={Number.parseFloat(
                        profitAndLoss.agentPercent
                      ).toFixed(3)}
                      onChange={({ target: { value } }) => {
                        profitAndLoss.setAgentPercent(value)
                      }}
                      placeholder="0"
                    />
                    <InputGroupAddon addonType="append">%</InputGroupAddon>
                  </InputGroup>
                </div>
              </div>
            </td>
            <td className="text-right">
              <Input
                type="tel"
                value={format(profitAndLoss.agentFees)}
                onChange={({ target: { value } }) => {
                  profitAndLoss.setAgentFees(value)
                }}
                placeholder="$0"
              />
            </td>
          </tr>
          <AddCustomField profitAndLoss={profitAndLoss} type={SALE_PROCEEDS} />
          {/*<tr>
          <th>
            <button onClick={e => this.addField(SALE_PROCEEDS)}>
              Add Custom Field
            </button>
          </th>
        </tr>*/}
        </tbody>
      </table>
      <table className="list-items table-accentuated table-borderless table-headed table table-sm">
        <tbody>
          <tr>
            <td>Total Sale Proceeds (GST Inclusive)</td>
            <td className="text-right">
              {format(profitAndLoss.saleProceedTotal)}
            </td>
          </tr>
          <tr>
            <td>GST Payable</td>
            <td className="text-right">
              {format(profitAndLoss.saleProceedGST)}
            </td>
          </tr>
          <tr>
            <td>Net Sale Proceeds (GST Exclusive)</td>
            <td className="text-right">
              {format(profitAndLoss.saleProceedNet)}
            </td>
          </tr>
          <tr>
            <td>Gross Margin (Net of GST)</td>
            <td className="text-right">
              {format(profitAndLoss.saleProceedGrossMargin)}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
)

SaleProceeds.propTypes = {
  profitAndLoss: MobxTypes.observableObject.isRequired,
  onChange: PropTypes.func.isRequired
}

export default SaleProceeds
