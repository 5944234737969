import React from "react"
import route from "./route"
import {
  ADD_PROJECT,
  ADD_PROPERTY,
  HOME,
  LOGIN,
  LOGOUT,
  PROJECT_PAGE,
  PROPERTY_PAGE,
  PLAN_PAGE,
  REGISTER,
  SELECT_AVATAR_PAGE,
  TEMPLATE_PAGE,
  TEMPLATES_PAGE,
  VERIFY_PAGE
} from "../models/ViewStore"

import Loading from "../components/Loading/index"
import LoginPage from "../components/LoginPage"
// import Home from "../components/Home"
// import PropertyPage from "../components/PropertyPage/index"
import AddProperty from "../components/AddProperty"
import PropertiesPage from "../components/PropertiesPage"
import PropertyPage from "../components/PropertyPage"
import SelectAvatar from "../components/SelectAvatar/index"
import RegisterPage from "../components/RegisterPage"
import ProjectPage from "../components/ProjectPage/index"
import TemplatesPage from "../components/TemplatesPage"
import TemplatePage from "../components/TemplatePage"
import AddProjectPage from "../components/AddProjectPage/index"
import SelectPlan from "../components/SelectPlan"

export const plans = route({
  template: "/plans",
  handle: viewer => viewer.openPlanPage,
  isActive: viewer => viewer.page === PLAN_PAGE,
  render: () => <SelectPlan />
})

export const register = route({
  template: "/register",
  handle: viewer => viewer.openRegisterPage,
  isActive: viewer => viewer.page === REGISTER,
  render: () => <RegisterPage />
})

export const login = route({
  template: "/login",
  handle: viewer => viewer.openLoginPage,
  isActive: viewer => viewer.page === LOGIN,
  render: viewer => <LoginPage {...viewer.params} />
})

export const logout = route({
  template: "/logout",
  handle: viewer => viewer.openLogoutPage,
  isActive: viewer => viewer.page === LOGOUT
})

export const home = route({
  template: "/",
  handle: viewer => viewer.openHomePage,
  isActive: viewer => viewer.page === HOME,
  render: () => <PropertiesPage />
})

export const selectAvatar = route({
  template: "/avatar",
  handle: viewer => viewer.openAvatarPage,
  isActive: viewer => viewer.page === SELECT_AVATAR_PAGE,
  render: viewer => <SelectAvatar user={viewer.user} />
})

export const addProperty = route({
  template: "/add-property",
  handle: viewer => viewer.openAddPropertyPage,
  isActive: viewer => viewer.page === ADD_PROPERTY,
  render: viewer => <AddProperty property={viewer.activeProperty} />
})

export const editProperty = route({
  template: "/property/edit/:id",
  handle: viewer => args => viewer.openPropertyPage({ ...args, edit: true }),
  isActive: viewer =>
    viewer.page === PROPERTY_PAGE && viewer.params.edit === true,
  argsFromState: viewer => ({
    ...viewer.params,
    id: viewer.propertyId
  }),
  render: viewer => <PropertyPage property={viewer.activeProperty} />
})

export const propertyPage = route({
  template: "/property/:id/:tab?",
  handle: viewer => viewer.openPropertyPage,
  isActive: viewer => viewer.page === PROPERTY_PAGE && !viewer.params.edit,
  argsFromState: viewer => ({
    id: viewer.propertyId,
    tab: viewer.params.tab || undefined,
    mode: viewer.params.mode || undefined,
    search: viewer.params.search || undefined
  }),
  render: viewer => (
    <PropertyPage
      property={viewer.activeProperty}
      defaultTab={viewer.params.tab}
    />
  )
})

export const addProject = route({
  template: "/add-project/:propertyId",
  handle: viewer => viewer.openAddProjectPage,
  isActive: viewer => viewer.page === ADD_PROJECT,
  argsFromState: viewer => ({
    ...viewer.params,
    propertyId: viewer.propertyId
  }),
  render: viewer => <AddProjectPage property={viewer.activeProperty} />
})

export const projectPage = route({
  template: "/project/:id/:tab?",
  handle: viewer => viewer.openProjectPage,
  isActive: viewer => viewer.page === PROJECT_PAGE,
  argsFromState: viewer => ({
    id: viewer.params.id,
    tab: viewer.params.tab || undefined,
    suggestions: viewer.params.suggestions || undefined
  }),
  render: viewer => (
    <ProjectPage
      project={viewer.activeProject}
      defaultTab={viewer.params.tab}
    />
  )
})

export const templatesPage = route({
  template: "/templates",
  handle: viewer => viewer.openTemplatesPage,
  isActive: viewer => viewer.page === TEMPLATES_PAGE,
  render: viewer => <TemplatesPage renoTracker={viewer.app} />
})

export const templatePage = route({
  template: "/template/:id",
  handle: viewer => viewer.openTemplatePage,
  isActive: viewer => viewer.page === TEMPLATE_PAGE,
  argsFromState: viewer => ({ id: viewer.params.id }),
  render: viewer => <TemplatePage template={viewer.activeTemplate} />
})

export const verifyEmailLoginPage = route({
  template: "/login-link/:key",
  handle: viewer => viewer.openVerifyPage,
  isActive: viewer => viewer.page === VERIFY_PAGE,
  argsFromState: viewer => ({ key: viewer.params.key }),
  render: viewer => <Loading />
})
