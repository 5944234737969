import history from "./history"
import { reaction } from "mobx"
import { applyFilters } from "./hooks"

export function init(viewer, initLocation) {
  // Listen to our State object
  reaction(
    () => viewer.currentUrl, // computed property
    url => {
      if (typeof url === "string") {
        // console.log("setting browser url from state to ", url)
        setHistory(url)
      }
    }
  )

  // And listen to the browsers history object,
  // ev.type === popstate ensures we only listen to native browser events,
  // not i.e. this won't listen to calls to history.push
  window.onpopstate = function historyChange(ev) {
    if (ev.type === "popstate") {
      // console.log("history has changed with", document.location)
      route(viewer, document.location)
    }
  }

  // Handle our existing location now
  route(viewer, initLocation)
}

/**
 * Update our state ( viewer ) based on this path
 * @param {object} viewer - see models/Viewer.js this is our MST root
 * @param {string|object} path - the path (URN) we want to route to
 * @param {object} path.pathname - /example/path
 * @param {object} path.search - ?hello=world
 */
export function route(viewer, path) {
  if (typeof path === "object") {
    path = `${path.pathname}${path.search}`
  }
  let args = null
  const link = applyFilters("routes", []).find(l => (args = l.matchPath(path)))
  if (link) {
    viewer.setRouteFound(true)
    // console.log('routing with', args, link.url())
    link.route(viewer, args)
  } else {
    // console.warn("Route not found for ", path)
    viewer.setRouteFound(false)
  }
}

/**
 * Set the displayed URL in the browser
 * @param {string} url - updates the windows location with url if url is different from current pathname
 */
function setHistory(url) {
  if (typeof window !== "undefined") {
    const { pathname, search } = new URL(url, window.location.href)
    if (window.location.pathname !== pathname) {
      // console.log("history push from ", window.location.pathname, "to", pathname)
      history.push(pathname)
    } else if (window.location.search !== search) {
      // console.log("history replace ?", 'window search', window.location.search, 'search', search, 'replacing with', pathname+search)
      history.replace(pathname + search)
    }
  }
}
