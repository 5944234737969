import React from "react"
import { Container, Row, Col } from "reactstrap"
import Notifications from "../Notifications/index"
import { observer, inject } from "mobx-react"

const BlankLayout = ({ children, renoTracker }) => (
  <div>
    <Container fluid={true}>
      <Row>
        <Col>
          <Notifications renoTracker={renoTracker} />
        </Col>
      </Row>
    </Container>
    {children}
  </div>
)

export default inject("renoTracker")(observer(BlankLayout))
