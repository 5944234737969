import { types, getParent } from "mobx-state-tree"
import { debounce } from "lodash/function"
import Node from "./Node"
import PriceField, { ACQUISITION, SALE_PROCEEDS, FINANCIAL } from "./PriceField"
import { TYPE_PROFIT_AND_LOSS } from "../constants"
// import { toNumber } from "lodash/lang"

function asNumber(value) {
  const number =
    typeof value === "number"
      ? value
      : parseFloat(String(value).replace(/[^0-9.-]/g, ""))
  return number || 0
}

export const fieldDefaults = {
  purchasePrice: {
    id: "purchasePrice",
    type: ACQUISITION,
    removable: false
  },
  renoCosts: {
    id: "renoCosts",
    type: ACQUISITION,
    removable: false,
    disabled: true
  },
  findersFee: { id: "findersFee", type: ACQUISITION },
  rates: { id: "rates", type: ACQUISITION },
  insurance: { id: "insurance", type: ACQUISITION },
  power: { id: "power", type: ACQUISITION },
  salePrice: { id: "salePrice", type: SALE_PROCEEDS, removable: false },
  // agentFees: { id: "agentFees", type: SALE_PROCEEDS },
  advertisingCosts: { id: "advertisingCosts", type: SALE_PROCEEDS },
  legalFees: { id: "legalFees", type: SALE_PROCEEDS },
  valuationCosts: { id: "valuationCosts", type: SALE_PROCEEDS },
  lendingCosts: {
    id: "lendingCosts",
    name: "Broker/Asset Lending Set-up Costs",
    type: FINANCIAL
  },
  interest: { id: "interest", type: FINANCIAL }
}

const ProfitAndLoss = types.compose(
  "ProfitAndLoss",
  Node,
  types
    .model({
      type: TYPE_PROFIT_AND_LOSS,
      address: types.optional(types.map(types.frozen()), {}),
      includeAgentFees: true,
      agentPercent: 4.3,
      agentFees: 0,
      fields: types.optional(types.map(PriceField), fieldDefaults)
    })
    .views(self => ({
      get allFields() {
        return Array.from(self.fields.values())
      },
      getFields(type, custom = false) {
        return self.allFields.filter(
          field => field.type === type && field.custom === custom
        )
      },
      getTotal(fields) {
        return fields.reduce((total, field) => total + field.value, 0)
      },
      getGST(amount) {
        return (amount * 3) / 23
      },
      get acquisitionFields() {
        return self.getFields(ACQUISITION)
      },
      get customAcquisitionFields() {
        return self.getFields(ACQUISITION, true)
      },
      get saleProceedFields() {
        return self.getFields(SALE_PROCEEDS)
      },
      get customSaleProceedFields() {
        return self.getFields(SALE_PROCEEDS, true)
      },
      get financialFields() {
        return self.getFields(FINANCIAL)
      },
      get customFinancialFields() {
        return self.getFields(FINANCIAL, true)
      },
      get acquisitionTotal() {
        return self.getTotal(
          self.acquisitionFields.concat(self.customAcquisitionFields)
        )
      },
      get saleProceedTotal() {
        // self.fields.salePrice -
        const total = self.getTotal(
          self.saleProceedFields
            .filter(field => field.id !== "salePrice")
            .concat(self.customSaleProceedFields)
        )
        return (
          self.fields.get("salePrice").value -
          (self.includeAgentFees ? total + self.agentFees : total)
        )
      },
      get financialTotal() {
        return self.getTotal(
          self.financialFields.concat(self.customFinancialFields)
        )
      },
      get acquisitionGST() {
        return self.getGST(self.acquisitionTotal)
      },
      get saleProceedGST() {
        return self.getGST(self.saleProceedTotal)
      },
      get financialGST() {
        return self.getGST(self.financialTotal)
      },
      get acquisitionNet() {
        return self.acquisitionTotal - self.acquisitionGST
      },
      get saleProceedNet() {
        return self.saleProceedTotal - self.saleProceedGST
      },
      get financialNet() {
        return self.financialTotal - self.financialGST
      },
      get saleProceedGrossMargin() {
        return self.saleProceedNet - self.acquisitionNet
      },
      get financialTaxableNetPL() {
        return self.saleProceedGrossMargin - self.financialTotal
      }
    }))
    .actions(self => ({
      setFromTemplate(profitAndLossTemplate) {
        if (profitAndLossTemplate) {
          self.allFields.forEach(field => {
            if (!field.value) {
              const templateField = profitAndLossTemplate.getForField(field)
              if (templateField) {
                field.value = templateField.value
                field.fromTemplate = true
              }
            }
          })
        }
      },
      setName(name, value) {
        self.fields.get(name).name = value
        self.debouncedSave()
      },
      setValue(name, value, save = true) {
        self.fields.get(name).value = value
        if (save) {
          self.debouncedSave()
        }
      },
      setPrice(name, value) {
        return self.setValue(name, asNumber(value))
      },
      setIncludeAgentFees(include) {
        self.includeAgentFees = include
        self.debouncedSave()
      },
      setAgentPercent(value) {
        value = Math.max(parseFloat(value) || 0, 0)
        self.agentPercent = value
        if (value > 0) {
          self.agentFees = self.fields.get("salePrice").value * (value / 100)
        } else {
          self.agentFees = 0
        }
        self.debouncedSave()
      },
      setAgentFees(value) {
        value = Math.max(asNumber(value), 0)
        self.agentFees = value
        if (value > 0) {
          self.agentPercent = Math.max(
            (value / self.fields.get("salePrice").value) * 100,
            0
          )
        } else {
          self.agentPercent = 0
        }
        self.debouncedSave()
      },
      addField(field) {
        self.fields.put(field)
        self.debouncedSave()
      },
      removeField(id) {
        self.fields.delete(id)
        self.debouncedSave()
      },
      save() {
        getParent(self).save()
      },
      debouncedSave: debounce(() => {
        self.save()
      }, 400)
    }))
)

export default ProfitAndLoss
