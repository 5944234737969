import React from "react"
import PropTypes from "prop-types"
import { observer, PropTypes as MobxTypes } from "mobx-react"
import { format } from "../../utils/price"
import { FINANCIAL } from "../../models/PriceField"
import FieldRow from "./FieldRow"
import AddCustomField from "./AddCustomField"
import { Label } from "reactstrap"

const Financial = observer(
  ({ profitAndLoss, onChange, onChangeName, onRemove }) => (
    <div>
      <table className="list-items list-items--transformer table-borderless table-headed table table-sm">
        <thead className="list-items__head">
          <tr className="list-items__head-row">
            <td
              style={{ width: "70%" }}
              className="list-items__head-description"
            >
              <h5>Items</h5>
            </td>
            <td className="text-right minw-5 list-items__head-budget">
              <Label>Value</Label>
            </td>
            <td className="text-right list-items__head-actions">
              {/* Actions */}
            </td>
          </tr>
        </thead>
        <tbody>
          {profitAndLoss.financialFields.map(field => {
            return (
              <FieldRow
                key={field.id}
                field={field}
                onChange={onChange}
                onRemove={field.removable ? onRemove : undefined}
              />
            )
          })}
          {profitAndLoss.customFinancialFields.map((field, idx) => (
            <FieldRow
              key={`${field.id}-${idx}`}
              field={field}
              onChange={onChange}
              onChangeName={onChangeName}
              onRemove={onRemove}
            />
          ))}
          <AddCustomField profitAndLoss={profitAndLoss} type={FINANCIAL} />
        </tbody>
      </table>
      <table className="list-items table-accentuated table-borderless table-headed table table-sm">
        <tbody>
          <tr>
            <td>Total Financing and Other Costs</td>
            <td className="text-right">
              {format(profitAndLoss.financialTotal)}
            </td>
          </tr>
          <tr>
            <td>Taxable Net Profit/Loss (Net of GST)</td>
            <td className="text-right">
              {format(profitAndLoss.financialTaxableNetPL)}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
)

Financial.propTypes = {
  profitAndLoss: MobxTypes.observableObject.isRequired,
  onChange: PropTypes.func.isRequired,
  onChangeName: PropTypes.func,
  onRemove: PropTypes.func
}

export default Financial
