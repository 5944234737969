import { types } from "mobx-state-tree"
import {
  STATUS_ACTIVE,
  STATUS_DRAFT,
  STATUS_COMPLETED,
  STATUS_DELETED
} from "../constants"
import { formatDate } from "../utils/date"

const WithStatus = types
  .model("WithStatus", {
    status: types.optional(
      types.enumeration("State", [
        STATUS_DRAFT,
        STATUS_ACTIVE,
        STATUS_COMPLETED,
        STATUS_DELETED
      ]),
      STATUS_DRAFT
    ),
    completedAt: 0
  })
  .views(self => ({
    get completedDate() {
      return formatDate(self.completedAt)
    }
  }))
  .volatile(self => ({
    statusChangedTo: null,
    statusChangedFrom: null
  }))
  .actions(self => {
    const superSave = self.save
    return {
      setStatus(status) {
        if (self.status !== status) {
          self.statusChangedFrom = self.status
          self.statusChangedTo = status
        }
        if (self.statusChangedTo === STATUS_COMPLETED) {
          self.completedAt = Date.now()
        }
        self.status = status
      },
      save() {
        if (self.neverSaved) {
          self.status = STATUS_ACTIVE
        }
        return superSave ? superSave() : null
      }
    }
  })

export default WithStatus
