import { types } from "mobx-state-tree"
import { unformat, format } from "../utils/price"
import { TYPE_ITEM, STATUS_ITEM_SUGGESTED, STATUS_ACTIVE } from "../constants"
import OverviewStats from "./OverviewStats"
import Node from "./Node"

const Item = types.compose(
  "Item",
  OverviewStats,
  Node,
  types
    .model({
      name: "",
      budget: 0,
      spent: 0,
      quantity: 1,
      suggestedBudget: 0,
      status: types.optional(
        types.enumeration("Status", [
          STATUS_ITEM_SUGGESTED,
          STATUS_ACTIVE,
          "default" // legacy
        ]),
        STATUS_ACTIVE
      ),
      type: TYPE_ITEM
    })
    .views(self => ({
      get available() {
        return self.totalBudget - self.spent
      },
      get totalBudget() {
        return self.budget * Math.max(self.quantity, 1)
      },
      get isSuggested() {
        return self.status === STATUS_ITEM_SUGGESTED
      },
      get suggestedBudgetFormated() {
        return self.suggestedBudget > 0 ? format(self.suggestedBudget) : ""
      }
    }))
    .actions(self => ({
      setName(value) {
        self.name = value
      },
      setBudget(value) {
        self.budget = unformat(value) / Math.max(self.quantity, 1)
      },
      setSpent(value) {
        self.spent = unformat(value)
      },
      setStatus(status) {
        self.status = status
      },
      toggleSuggested() {
        self.status =
          self.status === STATUS_ITEM_SUGGESTED
            ? STATUS_ACTIVE
            : STATUS_ITEM_SUGGESTED
      },
      setQuantity(value) {
        const q = Math.abs(parseInt(value, 10))
        if (q > 0) {
          self.quantity = q
        }
      }
    }))
)

export default Item
