import React from "react"
import { observer, PropTypes as MobxTypes } from "mobx-react"
import PropTypes from "prop-types"
import { Table, Label } from "reactstrap"
import ListSuggestedItem from "../ListSuggestedItem/index"

const ListSuggestedItems = ({ items, renderTitle, onAdd }) => (
  <Table
    size="sm"
    responsive
    className="list-items table-accentuated table-borderless table-headed"
  >
    <thead className="list-items__head">
      <tr className="list-items__head-row">
        <td style={{ width: "60%" }} className="list-items__head-description">
          {renderTitle ? (
            renderTitle()
          ) : (
            <h5>
              <span className="mr-2">Suggestions</span>
            </h5>
          )}
        </td>
        <td className="text-right">
          <Label>Guide</Label>
        </td>
        <td className="text-right">{/* Actions */}</td>
      </tr>
    </thead>
    <tbody className="list-items__body">
      {items.length > 0 &&
        items.map(item => (
          <ListSuggestedItem key={item.id} item={item} onAdd={onAdd} />
        ))}
      {items.length === 0 && (
        <tr>
          <td colSpan="3">
            <div className="p-3 small text-center">
              Looks like you've added all the suggested items for this category!
            </div>
          </td>
        </tr>
      )}
    </tbody>
  </Table>
)

ListSuggestedItems.propTypes = {
  items: MobxTypes.arrayOrObservableArrayOf(MobxTypes.observableObject)
    .isRequired,
  renderTitle: PropTypes.func,
  onAdd: PropTypes.func
}

export default observer(ListSuggestedItems)
