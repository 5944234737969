import React, { useReducer, useEffect, Fragment } from "react"
import { Elements } from "react-stripe-elements"
import StripePlans from "../StripePlans"
import StripePayment from "../StripePayment"
import Button from "../Button"
import { inject } from "mobx-react"
import { observer } from "mobx-react-lite"
import { updateCustomer } from "../../utils/stripe"
import { home } from "../../routes"
import Loading from "../Loading"

function reducer(state, action) {
  switch (action.type) {
    case "set-selected":
      return {
        ...state,
        plan: action.plan,
        product: action.product,
      }
    case "set-loading":
      return {
        ...state,
        loading: action.value,
      }
    default:
      return state
  }
}

const initialState = {
  loading: false,
}

function SelectPlan({ renoTracker }) {
  const [state, dispatch] = useReducer(reducer, initialState)
  const { plan: selectedPlan, product: selectedProduct, loading } = state
  const user = renoTracker.user
  const customerId = user.getMeta("stripeId")
  const setLoading = (v) => dispatch({ type: "set-loading", value: v })

  useEffect(() => {
    if (!customerId) {
      setLoading(true)
      user.saveToStripe().then(() => setLoading(false))
    }
  }, [customerId, user])

  const onCharge = (token) => {
    setLoading(true)
    updateCustomer(customerId, { source: token.id }).then(() => {
      activate(selectedPlan.id, selectedProduct.id)
      setLoading(false)
    })
  }

  const activate = (planId, productId) => {
    setLoading(true)
    user.stripeSubscribe(planId, productId).then(() => {
      home.route(renoTracker.view)
      setLoading(false)
    })
  }

  return (
    <div>
      <StripePlans
        activeSubscriptionId={user.getMeta("stripeSubscriptionId")}
        renderAction={(plan, product) => {
          const isSelected = selectedPlan && selectedPlan.id === plan.id
          if (loading) return <Loading />
          return (
            <Fragment>
              {isSelected && (
                <div style={{ flexBasis: "200%" }}>
                  <Elements>
                    <StripePayment
                      onSubmit={onCharge}
                      onCancel={() =>
                        dispatch({
                          type: "set-selected",
                          plan: undefined,
                          product: undefined,
                        })
                      }
                      tokenData={{
                        name: user.getMeta("fullName"),
                      }}
                    />
                  </Elements>
                </div>
              )}
              {!isSelected && (
                <Button
                  onClick={() => {
                    if (plan.amount === 0) {
                      activate(plan.id, product.id)
                    } else {
                      dispatch({ type: "set-selected", plan, product })
                    }
                  }}
                  color="primary"
                  className="plan-select"
                >
                  Select
                </Button>
              )}
            </Fragment>
          )
        }}
      />
    </div>
  )
}

export default inject("renoTracker")(observer(SelectPlan))
