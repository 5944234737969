import { types, flow, getSnapshot } from "mobx-state-tree"
import Fuse from "fuse.js"
import { omit } from "lodash/object"
import Project from "./Project"
import {
  TYPE_PROPERTY,
  TYPE_PROJECT,
  STATUS_ACTIVE,
  STATUS_DRAFT
} from "../constants"
import db from "../utils/db"
import OverviewSum from "./OverviewSum"
import Node from "./Node"
import Document from "./Document"
import WithStatus from "./WithStatus"
import WithTime from "./WithTime"
import { generate } from "../utils/uuid"
import ProfitAndLoss from "./ProfitAndLoss"

function sortProjects(a, b) {
  const nameA = a.name.toUpperCase()
  const nameB = b.name.toUpperCase()
  if (nameA < nameB) {
    return -1
  }
  if (nameA > nameB) {
    return 1
  }
  return 0
}

const Property = types.compose(
  "Property",
  WithTime,
  OverviewSum,
  Document,
  WithStatus,
  Node,
  types
    .model({
      name: "",
      type: TYPE_PROPERTY,
      projects: types.optional(types.map(Project), {}),
      address: types.optional(types.map(types.frozen()), {}),
      // activeProject: types.maybeNull(types.reference(Project)),
      searchMatchedItemIds: types.optional(types.array(types.string), []),
      profitAndLoss: types.optional(ProfitAndLoss, {}),
      sharedWith: types.optional(types.array(types.string), [])
    })
    .views(self => {
      const superGetCouchFields = self.getCouchFields
      return {
        get legacyAttachment() {
          return self.app.attachments.has(self.id)
        },
        get activeProjects() {
          return self.allProjects
            .filter(p => p.status === STATUS_ACTIVE)
            .sort(sortProjects)
        },
        get allProjects() {
          return Array.from(self.projects.values())
        },
        get imageSrc() {
          return self.legacyAttachment
            ? self.app.getImageSrc(self.id)
            : self.getImageSrc(self.id)
        },
        get activeProjectsWithImages() {
          return self.activeProjects.reduce((sum, project) => {
            const images = project.hasImages

            return sum + images ? 1 : 0
          }, 0)
        },
        sumField(field) {
          return Array.from(self.projects.values()).reduce(
            (sum, project) => (sum += project[field]),
            0
          )
        },
        get activeItems() {
          return self.activeProjects.reduce(
            (items, p) => items.concat(p.activeItems),
            []
          )
        },
        get activeItemNames() {
          return self.activeItems.map(i => i.name)
        },
        get unusedTemplates() {
          const usedTemplatesIds = self.activeProjects
            .filter(p => !!p.template)
            .map(p => p.template.id)
          return self.app.activeTemplates.filter(
            template => !usedTemplatesIds.includes(template.id)
          )
        },
        get searchableItems() {
          return self.activeProjects
            .concat(self.unusedTemplates)
            .reduce((r, p) => r.concat(p.searchableItems), [])
        },
        get neverSaved() {
          return self.status === STATUS_DRAFT
        },
        getCouchFields() {
          const fields = superGetCouchFields()
          return omit(fields, [
            "projects",
            "rev",
            // "attachments",
            // "blobs",
            "searchMatchedItemIds",
            "activeProject"
          ])
        },
        get activeProject() {
          return self.projects.get(self.activeProjectId)
        }
      }
    })
    .volatile(self => ({
      dbPercent: null,
      fuse: null,
      activeProjectId: null
    }))
    .actions(self => {
      const superSave = self.save
      const superLoadImage = self.loadImage
      const superAddImage = self.addImage
      const superLoad = self.load
      return {
        setPercent(value) {
          self.dbPercent = value
        },

        addProject(project) {
          if (!project) {
            project = Project.create({ id: generate() })
          }
          return self.projects.put(project)
        },

        setName(name) {
          self.name = name
        },

        setAddress(address) {
          self.address = address
          self.name = [address.address1, address.address2].join(", ")
        },

        save: flow(function*(update) {
          let saveApp = false
          if (self.legacyAttachment) {
            console.log("moving legacy attachments")
            const attachment = self.app.attachments.get(self.id)
            const data = yield self.db.getAttachment(self.app.id, self.id)

            self.attachments.set(self.id, {
              content_type: attachment.content_type,
              data
            })

            self.app.deleteAttachment(self.id)
            saveApp = true
          }
          if (!self.app.propertyIds.includes(self.id)) {
            self.app.addPropertyId(self.id)
            saveApp = true
          }

          const res = yield superSave(update)

          if (saveApp) {
            console.log("saving app")
            self.app.addPropertyId(self.id)
            yield self.app.save()
          }
          return res
          // console.log(`do I need to save my parent app here
          // in order to update its propertyIds array. Probably not
          // that could happen on create only`)
        }),

        load() {
          return superLoad().then(() => self.loadProjects())
        },

        loadProjects: flow(function*() {
          yield db.createIndex({
            index: {
              fields: ["type", "propertyId"]
            }
          })
          const res = yield db.find({
            selector: {
              type: TYPE_PROJECT,
              propertyId: self.id
            },
            fields: ["_id", "template"]
          })

          yield Promise.all(
            res.docs
              .filter(doc => !self.projects.has(doc._id))
              .map(doc => {
                const id = doc._id
                self.projects.put({ id })
                return self.projects
                  .get(id)
                  .load()
                  .catch(e => {
                    if (e.status === 404) {
                      console.warn(`Can't find template: ${id}`)
                    } else throw e
                  })
              })
          )

          // res.docs.map(renameForState).forEach(model => {
          //   self.projects.put(model)
          //   self.projects.get(model.id).loadAttachments()
          // })

          yield self.app.loadTemplates(
            res.docs.map(doc => doc.template).filter(Boolean)
          )
        }),

        setActiveProject(id) {
          return (self.activeProjectId = id)
        },

        addImage(file) {
          return superAddImage(file, self.id)
        },

        loadImage() {
          if (self.legacyAttachment) {
            // legacy, when properties where part of self.app
            return self.app.loadAttachment(self.id)
          }
          return superLoadImage(self.id)
        },

        initFuse() {
          if (!self.fuse) {
            const options = {
              location: 0,
              threshold: 0.3,
              minMatchCharLength: 2,
              distance: 100,
              keys: ["name"]
            }

            self.fuse = new Fuse(self.searchableItems, options)
          }
        },

        resetFuse() {
          self.fuse = null
        },

        search(search) {
          self.initFuse()
          const res = self.fuse.search(search)
          self.searchMatchedItemIds = res.map(i => i.id)
        },

        clearSearch() {
          self.searchMatchedItemIds = []
        },

        createProjectFromTemplate(template) {
          const project = Project.create({ id: generate() })
          project.setTemplate(template.id)
          project.setName(template.name)
          template.activeItems.forEach(item => {
            project.addSuggestion(item)
          })
          self.addProject(project)
          return project
        },

        clone: flow(function*(data = {}) {
          const cloneData = {
            ...getSnapshot(self),
            ...data,
            id: generate(),
            projects: undefined
          }
          const property = self.app.addProperty(cloneData)
          yield property.save()

          yield Promise.all(
            self.activeProjects
              .map(project => project.cloneData)
              .map(data => {
                const projectClone = Project.create({
                  propertyId: property.id,
                  ...data
                })
                property.addProject(projectClone)
                return projectClone.save()
              })
          )

          return property
        })
      }
    })
)

export default Property
