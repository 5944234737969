import { types, flow, applySnapshot, getSnapshot } from "mobx-state-tree"
import { omit, get } from "lodash/object"
import Node from "./Node"
import db, { renameCouchFields, renameForState } from "../utils/db"
import { STATUS_DRAFT, STATUS_ACTIVE } from "../constants"
// import { addAction, removeAction } from "../utils/hooks"

const Document = types.compose(
  "Document",
  Node,
  types
    .model({
      rev: types.maybeNull(types.string),
      deleted: false,
      attachments: types.optional(types.map(types.frozen()), {}),
      attachmentUrls: types.optional(types.map(types.frozen()), {}),
      author: "",
      status: types.optional(
        types.enumeration("Status", [STATUS_DRAFT, STATUS_ACTIVE]),
        STATUS_DRAFT
      )
    })
    .volatile(self => ({
      validationFailures: [],
      saved: true, // see Project.js where  I'm adjusting this with afterCreate
      blobs: new Map()
    }))
    .views(self => ({
      get dbSnapshot() {
        return self.getCouchFields()
      },
      // Not a get so I can override
      // Rename fields for couchDB
      getCouchFields() {
        return renameCouchFields(omit(getSnapshot(self), ["attachmentUrls"]))
      },
      get isValid() {
        return self.validationFailures.length === 0
      },
      getImageSrc(name) {
        name = name || self.id

        if (self.attachments.has(name)) {
          const attachment = self.attachments.get(name)
          const preview = get(attachment, ["data", "preview"])
          if (preview) {
            return preview
          }
        }

        return self.attachmentUrls.has(name)
          ? self.attachmentUrls.get(name)
          : null
      },
      get db() {
        return db
      },
      get neverSaved() {
        return !self.rev
      }
    }))
    .actions(self => {
      // const syncChange = (id, rev) => {
      //   if (id === self.id && rev !== self.rev) {
      //     console.log("rev changed so loading")
      //     self.load(id)
      //   }
      // }

      return {
        addValidationFailure(message, attribute) {
          self.validationFailures.push({ message, attribute })
        },

        validate() {
          console.warn("Validate method not implemented")
        },

        clearValidationFailures() {
          self.validationFailures = []
        },

        save: flow(function*(update = true) {
          if (self.validationFailures.length) {
            const e = new Error("Validation Failed")
            e.errors = self.validationFailures
            throw e
          }
          if (self.app.user) {
            // Note. if creating outside the tree then self.app.user will be null.
            self.author = self.app.user.name
          } else {
            console.warn(
              "self.app.user isn't set. Are you creating outside the Tree?"
            )
          }

          let res

          if (update) {
            res = yield self.id
              ? self.db.upsert(self.id, () => self.dbSnapshot)
              : self.db.put(self.dbSnapshot)
          } else {
            res = yield self.db.post(self.dbSnapshot)
          }

          if (res.updated) {
            self.rev = res.rev
            self.id = res.id
            self.loadAttachments(true)
            self.setSaved(true)
          }
          return res
        }),

        delete() {
          self.deleted = true
          return self.db.put(self.dbSnapshot)
        },

        // The reverse of getCouchFields, renames couchDB fields for MST
        convertCouchFields(doc) {
          return renameForState(doc)
        },

        load: flow(function*(byId) {
          const id = byId || self.id
          const doc = yield self.db.get(id || self.id)
          if (doc) {
            // console.log(`loading ${self.type}`, id)
            self.setFromDoc(doc)
          }
          // don't need this to block
          self.loadAttachments()
          return self
        }),

        setFromDoc(doc) {
          const snapshot = self.convertCouchFields(doc)
          applySnapshot(self, snapshot)
          return self
        },

        addImage(file, name) {
          return self.addAttachment(file, name || file.name)
        },

        loadImage(name) {
          return self.loadAttachment(name || self.id)
        },

        addAttachment(file, id) {
          id = id || file.name
          self.attachments.set(id, {
            content_type: file.type,
            data: file
          })
        },

        deleteAttachment(id) {
          self.attachments.delete(id)
          self.attachmentUrls.delete(id)
          self.db.removeAttachment(self.id, id, self.rev)
        },

        // Read more here
        // https://pouchdb.com/guides/attachments.html
        loadAttachment: flow(function*(id, { force = false } = {}) {
          // console.log("loading attachment", id)
          if (force || !self.attachmentUrls.has(id)) {
            try {
              if (force || !self.blobs.has(id)) {
                // console.log("fetching")
                const blob = yield self.db.getAttachment(self.id, id)
                if (blob) {
                  // console.log("working")
                  self.blobs.set(id, URL.createObjectURL(blob))
                }
              }
              const url = self.blobs.get(id)
              if (url) {
                // console.log("storing")
                self.attachmentUrls.set(id, url)
              }
            } catch (e) {
              if (e.status === 404) {
                console.log("Attachment not found")
              } else {
                throw e
              }
            }
          }
        }),

        loadAttachments(force = false) {
          return Promise.all(
            Array.from(self.attachments.keys()).map(id =>
              self.loadAttachment(id, { force })
            )
          )
        },

        setSaved(saved) {
          self.saved = saved
        }

        // afterCreate() {
        //   console.log("in after attach")
        //   addAction("sync-change", syncChange)
        // },

        // beforeDetach() {
        //   console.log("in before detache")
        //   removeAction("sync-change", syncChange)
        // }
      }
    })
)

export default Document
