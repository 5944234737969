import React, { Component, Fragment } from "react"
import { Nav, NavItem, Form, Input, Row, Col, Card, CardBody } from "reactstrap"
import { curry, debounce } from "lodash/function"
import classnames from "classnames"
import { observer, inject } from "mobx-react"
import ListItems from "../ListItems"
import Overview from "../Overview"
import SaveAsTemplateButton from "../SaveAsTemplateButton"
import Notes from "../Notes/index"
import Link from "../Link"
import { propertyPage } from "../../routes/"
import {
  STATUS_COMPLETED,
  IMG_DEFAULT_WIDTH,
  IMG_DEFAULT_HEIGHT,
  STATUS_DELETED
} from "../../constants"
import Button from "../Button/index"
import Progress from "../Progress"
import Icon from "../Icon"
import NavLink from "../NavLink/index"
import PropertyLink from "../PropertyLink"
import ListSuggestedItems from "../ListSuggestedItems/index"
// import { ReactComponent as Plus } from "../../images/white-plus.inline.svg"

import "./ProjectPage.scss"
import ImageSelector from "./ImageSelector"

const imageSize = { width: IMG_DEFAULT_WIDTH, height: IMG_DEFAULT_HEIGHT }

const TAB_BUDGET = "budget"
const TAB_GALLERY = "gallery"

class ProjectPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      saving: false,
      tab: props.defaultTab
    }
  }

  static defaultProps = {
    defaultTab: TAB_BUDGET
  }

  onSave = async e => {
    if (e) e.preventDefault()
    this.setState({ saving: true })
    const { project } = this.props
    project.validate()
    if (project.isValid) {
      await project.save()
      // project.app.notify("Budget category updated!")
      // return propertyPage.route(project.viewer, {
      //   id: project.property.id
      // })
    } else {
      project.validationFailures.map(({ message }) =>
        project.app.notify(message, { level: "warning" })
      )
    }
    this.setState({ saving: false })
  }

  onDelete = async () => {
    const sure = window.confirm(
      "Are you sure you want to delete this category?"
    )
    if (sure) {
      const { project } = this.props
      project.setStatus(STATUS_DELETED)
      await project.save()
      return propertyPage.route(project.viewer, {
        id: project.property.id
      })
    }
  }

  onDrop = curry((name, file) => {
    const { project } = this.props
    project.addImage(file, name)
    // project.setSaved(false)
    this.onSave()
  })

  debounceSaved = debounce(() => {
    this.onSave()
  }, 800)

  addItem = () => {
    this.props.project.addItem()
    this.onSave()
  }

  addSuggestion = suggestion => {
    this.props.project.addSuggestion(suggestion)
    this.onSave()
  }

  deleteItem = item => {
    if (window.confirm("Are you sure you want to remove this item?")) {
      this.props.project.deleteItem(item.id)
      this.onSave()
    }
  }

  toggleSuggestions = () => {
    const params = this.props.viewer.params
    this.props.viewer.setParams({
      suggestions: !params.suggestions
    })
  }

  handleAddImages = e => {
    e.preventDefault()

    this.props.project.addNewImage()
    this.onSave()
  }

  handleDeleteImages = (e, name) => {
    e.preventDefault()

    const sure = window.confirm("Are you sure you want to delete this image?")
    if (sure) {
      this.props.project.deleteImage(name)
      this.onSave()
    }
  }

  render() {
    const {
      project,
      viewer: {
        app: renoTracker,
        params: { suggestions: showingSuggestions }
      }
    } = this.props
    const { tab } = this.state
    const propertyUrl = propertyPage.url({ id: project.property.id })
    const haveSuggestions = project.availableSuggestions.length > 1

    const progressValue = project.percentUsed
    // note we process the percent when passing it into progress bar, as we want 117% to display like 17% not 100% (and then we add red coloring to show it is 'over')
    const progressBarValue =
      progressValue > 100 ? progressValue - 100 : progressValue
    const progressBarText =
      project.status === STATUS_COMPLETED ? "" : project.percentUsedFormatted
    const progressBarIcon =
      project.status === STATUS_COMPLETED ? (
        <Icon icon="check" size="lg" />
      ) : (
        false
      )

    return (
      <span>
        <div className="back-button-top-container">
          <Link to={propertyUrl}>
            <Button
              swatch
              disabled={!project.saved}
              color="secondary"
              className={classnames("back-button-top")}
              size="sm"
            >
              {!project.saved && "Saving"}
              {project.saved && "Back"}
            </Button>
          </Link>
        </div>
        <h1 className="swatch-title swatch-title--main swatch-title--main-small">
          <small>View + Edit</small>
          Budget
        </h1>

        <Card className="card-raised mw-70 m-auto">
          <Form>
            <CardBody>
              <Row className="row-nowrap">
                <Col xs="auto">
                  <Progress
                    className="project-detail-progress"
                    percentage={progressBarValue}
                    text={progressBarText}
                    icon={progressBarIcon}
                    status={progressValue > 100 ? "danger" : ""}
                    withBG
                  />
                </Col>
                <Col xs="auto" className="col-fill">
                  <PropertyLink property={project.property} />
                  <div className="mt-2">
                    <h4>
                      <Input
                        value={project.name}
                        onChange={({ target: { value } }) => {
                          project.setName(value)
                          this.debounceSaved()
                        }}
                        placeholder="Enter Category Name"
                      />
                    </h4>

                    <Nav className="property-detail-nav">
                      {project.activeItems.length > 0 &&
                        renoTracker.user.isAdmin && (
                          <NavItem>
                            <SaveAsTemplateButton size="xs" project={project} />
                          </NavItem>
                        )}
                      <NavItem className="ml-auto">
                        <Button
                          icon
                          onClick={this.onDelete}
                          title="Delete this category and all of it's items"
                          className="text-danger"
                        >
                          <Icon icon="delete" /> Delete Category
                        </Button>
                      </NavItem>
                    </Nav>
                  </div>
                </Col>
              </Row>

              {project.totalBudget > 0 && (
                <div className="mt-2">
                  <Overview model={project} />
                </div>
              )}
            </CardBody>

            <Nav tabs>
              <NavItem active={tab === TAB_BUDGET}>
                <span
                  className="btn as-link"
                  onClick={() => {
                    this.setState({ tab: TAB_BUDGET })
                  }}
                  title="View budget items in this category"
                >
                  <Icon icon="toc" size="md" /> Budget Items
                </span>
              </NavItem>
              <NavItem active={tab === TAB_GALLERY}>
                <span
                  className="btn as-link"
                  onClick={() => {
                    this.setState({ tab: TAB_GALLERY })
                  }}
                  title="View images of this category"
                >
                  <Icon icon="photo_camera" size="md" /> Photos
                </span>
              </NavItem>
            </Nav>

            <CardBody>
              {tab === TAB_BUDGET && (
                <Fragment>
                  <div className="mb-4">
                    <ListItems
                      items={project.activeItems}
                      onDelete={this.deleteItem}
                      onAdd={this.addItem}
                      onChange={this.debounceSaved}
                      showingSuggestions={showingSuggestions}
                      onShowSuggestions={
                        haveSuggestions ? this.toggleSuggestions : null
                      }
                      onSort={field => project.setSort(field)}
                    />
                  </div>
                  {showingSuggestions &&
                    project.availableSuggestions.length > 0 && (
                      <div className="mb-4">
                        <ListSuggestedItems
                          items={project.availableSuggestions}
                          onAdd={this.addSuggestion}
                        />
                      </div>
                    )}

                  <Notes node={project} onChange={this.debounceSaved} />
                </Fragment>
              )}

              {tab === TAB_GALLERY && (
                <ImageSelector
                  imageSize={imageSize}
                  project={project}
                  onDrop={this.onDrop}
                  onDelete={this.handleDeleteImages}
                  onAdd={this.handleAddImages}
                />
              )}

              <Nav className="justify-content-center align-items-center nav-space-items">
                <NavItem>
                  <NavLink href={propertyUrl}>
                    <Button swatch color="secondary" disabled={!project.saved}>
                      {!project.saved && "Saving"}
                      {project.saved && "Back"}
                    </Button>
                  </NavLink>
                </NavItem>
              </Nav>
            </CardBody>
          </Form>
        </Card>
      </span>
    )
  }
}

export default inject("viewer")(observer(ProjectPage))
