import { types, getRoot } from "mobx-state-tree"
import { idType } from "../utils/model"
import db from "../utils/db"

export const createTypeIndex = () =>
  db.createIndex({ index: { fields: ["type"] } })

// Abstract base type
const Node = types
  .model("Node", {
    id: idType(),
    type: types.string
  })
  .views(self => ({
    get app() {
      return getRoot(self)
    },
    get viewer() {
      return getRoot(self).view
    }
  }))

export default Node
