import numeral from "numeral"
const THOUSANDS_FORMAT = "$0,0"

export function format(value) {
  return numeral(value).format(THOUSANDS_FORMAT)
}

export function unformat(value) {
  return typeof value === "number"
    ? value
    : Number(value.replace(/[^0-9.-]/g, ""))
}
